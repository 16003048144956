/* React */
import React from "react";

/* Ionic */
import { IonMenuButton, IonIcon } from "@ionic/react";
import { close, settings } from "ionicons/icons";

/* React-Redux */
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import { setIsOpen } from "src/redux/features/insights/insights-filter/insightsFilterMenuSlice";

/* Component */
import UserMenu from "src/components/menus/user-menu/UserMenu";

/* Stylesheet */
import styles from "./AppToolbar.module.scss";

/* Interface */
interface AppToolbarProps {
  component?: React.ReactNode;
}

const AppToolbar: React.FC<AppToolbarProps> = () => {
  const dispatch = useDispatch();

  // pageTitleState
  const pageTitleState = useSelector((state: RootState) => state.pageTitle);

  const insightsFilterMenuState = useSelector(
    (state: RootState) => state.insightsFilterMenu
  );

  const { isOpen, disabled } = insightsFilterMenuState;

  return (
    <div className={styles.app_toolbar}>
      <div className={styles.menu_button_container}>
        <IonMenuButton
          data-testid="main-menu-button"
          className={styles.menu_button}
          menu="main-menu"
        />
        {pageTitleState.title ? (
          <div className={styles.page_title}>{pageTitleState.title}</div>
        ) : undefined}
      </div>
      <div className={styles.options_container}>
        {!disabled ? (
          <IonMenuButton
            style={{ "--background": isOpen ? "#F4F5F6" : undefined }}
            className={styles.filter_menu_button}
            menu="filter-menu"
            onClick={() => dispatch(setIsOpen(!isOpen))}
          >
            <IonIcon className={styles.icon} icon={isOpen ? close : settings} />
          </IonMenuButton>
        ) : undefined}
        <UserMenu />
      </div>
    </div>
  );
};

export default AppToolbar;
