/* color-mapper.ts */

/* Constants */
import {
  GENERIC_GRADIENT,
  TREATMENT_COLORS,
} from "src/shared/constants/Colors";

export const colorMapper = (index: number) => {
  return GENERIC_GRADIENT[index];
};

export const treatmentColorMapper = (
  index: number,
  values: string | string[]
) => {
  return TREATMENT_COLORS[index % TREATMENT_COLORS.length][
    values.indexOf(values[index])
  ];
};
