/* Colors.ts */

export const GENERIC_GRADIENT = [
  "#469bd7",
  "#85c3e8",
  "#2a6f9e",
  "#46d7c1",
  "#8ee8d8",
  "#46d777",
  "#a8e88b",
  "#d7d746",
  "#d78a46",
  "#d7464f",
];

export const MATCHING_GRADIENT = [
  "#64bdaa",
  "#0eaeb7",
  "#009bc2",
  "#0084c4",
  "#526ab6",
  "#794b96",
];

export const TREATMENT_COLORS = [
  [
    "#67BDAB",
    "#4ea5a1",
    "#3b8d94",
    "#357583",
    "#335e6f",
    "#2f4858",
    "#469bd7",
    "#00b7e1",
    "#00d0d3",
    "#3ee4b2",
    "#a2f28b",
    "#f9f871",
  ],
  [
    "#64bdaa",
    "#0eaeb7",
    "#009bc2",
    "#0084c4",
    "#526ab6",
    "#794b96",
    "#469bd7",
    "#5d8ad1",
    "#7577c4",
    "#8b61af",
    "#9b4a93",
    "#a33272",
  ],
];
