/* React */
import React from "react";

/* React-Redux */
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store";

/* Components */
import SankeyDiagramOptions from "src/components/insights/insights-options/sankey-diagram-options/SankeyDiagramOptions";
import SurvivalAnalysisOptions from "src/components/insights/insights-options/survival-analysis-options/SurvivalAnalysisOptions";

/* Stylesheet */
import styles from "./InsightsOptions.module.scss";

/* Interface */
import { ChartType } from "src/shared/interface/charts/BaseChart";

const InsightsOptions: React.FC = () => {
  const insightsFilterOptionsState = useSelector(
    (state: RootState) => state.insightsFilterOptions
  );

  const { options } = insightsFilterOptionsState;

  const mapTypeToComponent = (type: string) => {
    switch (type) {
      case ChartType.HISTOGRAM:
        return <div />;
      case ChartType.SCATTER:
        return <SurvivalAnalysisOptions />;
      case ChartType.SANKEY:
        return <SankeyDiagramOptions />;
      default:
        return null;
    }
  };

  return (
    <div className={styles.insights_options}>
      <div className={styles.content_container}>
        {options ? mapTypeToComponent(options) : undefined}
      </div>
    </div>
  );
};

export default InsightsOptions;
