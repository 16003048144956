/* insightsFilterMenuSlice.ts */

/* Redux-Toolkit */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  disabled: true,
  isOpen: false,
};

export const insightsFilterMenuSlice = createSlice({
  name: "insightsFilterMenu",
  initialState: initialState,
  reducers: {
    setDisabled: (state, action: PayloadAction<boolean>) => {
      state.disabled = action.payload;
    },
    setIsOpen: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload;
    },
  },
});

/* Action(s) */
export const { setDisabled, setIsOpen } = insightsFilterMenuSlice.actions;

/* Reducer */
export default insightsFilterMenuSlice.reducer;
