/* React */
import React, { useEffect } from "react";

/* React-Redux */
import { useSelector, useDispatch } from "react-redux";
import {
  setStartPoint,
  setShowConfidenceIntervals,
} from "src/redux/features/insights-options/survival-analysis/survivalAnalysisOptionsSlice";
import { AppDispatch, RootState } from "src/redux/store";

/* Thunk */
import { fetchStartPoints } from "src/redux/thunks/survival-analysis/survivalAnalysisThunk";

/* Components */
import SimpleSelectContainer from "src/components/shared/simple-select-container/SimpleSelectContainer";

/* Stylesheet */
import styles from "./SurvivalAnalysisOptions.module.scss";
import ConfidenceInterval from "./confidence-interval/ConfidenceInterval";

const SurvivalPlotOptions: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  // survivalPlotOptionsState
  const survivalAnalysisOptionsState = useSelector(
    (state: RootState) => state.survivalAnalysisOptions
  );

  const { showConfidenceIntervals, startPoint } = survivalAnalysisOptionsState;

  const handleItemClick = () => {
    dispatch(setShowConfidenceIntervals(!showConfidenceIntervals));
  };

  const handleStartPoint = (value: string) => {
    dispatch(setStartPoint(value));
  };

  const handleCheckboxChange = (e: CustomEvent) => {
    /**
     * e.stopPropagation() is used to prevent the click event from bubbling up the DOM tree,
     * preventing any parent handlers from being notified of the event
     **/
    e.stopPropagation();
  };

  useEffect(() => {
    dispatch(fetchStartPoints());
  }, [dispatch]);

  return (
    <div className={styles.survival_analysis_options}>
      <SimpleSelectContainer
        label={"Start point"}
        item={startPoint}
        handleItem={handleStartPoint}
      />
      <ConfidenceInterval
        showConfidenceIntervals={showConfidenceIntervals}
        handleItemClick={handleItemClick}
        handleCheckboxChange={handleCheckboxChange}
      />
    </div>
  );
};

export default SurvivalPlotOptions;
