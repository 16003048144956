/* survivalPlotOptionsSlice.ts */

/* React-Toolkit */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

/* Thunk */
import { fetchStartPoints } from "src/redux/thunks/survival-analysis/survivalAnalysisThunk";

/* Interface */
import { SurvivalAnalysisOptionsProps } from "src/shared/interface/options/SurvivalPlotOptions";

const initialState: SurvivalAnalysisOptionsProps = {
  startPoint: {
    value: undefined,
    items: [],
    disabled: false,
  },
  showConfidenceIntervals: false,
};

export const survivalAnalysisOptionsSlice = createSlice({
  name: "survivalPlotOptions",
  initialState: initialState,
  reducers: {
    setStartPoint: (state, action: PayloadAction<string>) => {
      state.startPoint.value = action.payload;
    },
    setShowConfidenceIntervals: (state, action: PayloadAction<boolean>) => {
      state.showConfidenceIntervals = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchStartPoints.fulfilled,
      (state, action: PayloadAction<{ items: string[] }>) => {
        if (!state.startPoint.value) {
          state.startPoint.value = action.payload.items[0];
        }
        state.startPoint.items = action.payload.items;
      }
    );
  },
});

/* Action */
export const { setStartPoint, setShowConfidenceIntervals } =
  survivalAnalysisOptionsSlice.actions;

/* Reducer */
export default survivalAnalysisOptionsSlice.reducer;
