/* React */
import React from "react";

/* Ionic */
import { IonItem } from "@ionic/react";

/* Stylesheet */
import styles from "./ValueContainer.module.scss";

/* Interface */
interface ValueContainerProps {
  items: string[];
  handleItem: (item: string) => void;
  handleValues: (value: boolean) => void;
}

const ValueContainer: React.FC<ValueContainerProps> = ({
  items,
  handleItem,
  handleValues,
}) => {
  return (
    <div className={styles.value_container}>
      {items.map((item, index) => (
        <IonItem
          key={index}
          className={styles.value_item}
          lines="none"
          button
          onClick={() => {
            handleItem(item);
            handleValues(false);
          }}
        >
          {item}
        </IonItem>
      ))}
    </div>
  );
};

export default ValueContainer;
