/**
 * @file QueryListContainer.tsx
 *
 * @desc
 * The component is used to show the current selected main condition and filter values.
 * By clicking on the chip, the data with the initial value will be fetched.
 * The user does not need to open the insights filter menu to reset the filter values.
 */

/* React */
import React from "react";

/* React-Responsive */
import { useMediaQuery } from "react-responsive";

/* Components */
import QueryList from "src/components/insights/query-list-container/query-list/QueryList";
import MobileContainer from "src/components/insights/query-list-container/mobile-container/MobileContainer";

/* Stylesheet */
import styles from "./QueryListContainer.module.scss";

const QueryListContainer: React.FC = () => {
  const isDesktop = useMediaQuery({ query: "(min-width: 1024px)" });

  return (
    <div className={styles.query_list_container}>
      {isDesktop ? (
        <QueryList scssProps={{ display: "flex" }} />
      ) : (
        <MobileContainer />
      )}
    </div>
  );
};

export default QueryListContainer;
