/* useChartRenderer.ts */

/* React */
import { useState, useEffect } from "react";

/* Interface */
interface UseChartRendererProps {
  timeout: number;
}

const useChartRenderer = ({ timeout }: UseChartRendererProps) => {
  // isLoading
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    /**
     * This is a workaround to prevent the chart from flickering when loading.
     * Otherwise, the following error is shown: 'Resize must be passed a displayed plot div element.'
     * This is because, useReziseHandler is called before the chart is rendered in the DOM.
     **/
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, timeout);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return { isLoading };
};

export default useChartRenderer;
