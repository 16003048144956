/* React */
import React from "react";

/* Ionic */
import { IonItem, IonIcon } from "@ionic/react";
import { closeCircle } from "ionicons/icons";

/* Stylesheet */
import styles from "./QueryItem.module.scss";

/* Interfaces */
import { FilterProps } from "src/shared/interface/common/InsightsFilter";

interface ChipProps<T> {
  scssProps?: ScssProps;
  filter: FilterProps<T>;
  onClick?: () => void;
}

interface ScssProps {
  color?: string;
  backgroundColor?: string;
}

const QueryItem: React.FC<ChipProps<string | undefined>> = ({
  scssProps,
  filter,
  onClick,
}) => {
  const disabled = filter.initialValue === filter.value;

  return (
    <IonItem
      style={{
        "--padding-end": disabled ? "12px" : undefined,
        "--background": scssProps?.backgroundColor,
      }}
      className={styles.query_item}
      disabled={disabled}
      onClick={onClick}
      button
      lines="none"
    >
      <div style={{ color: scssProps?.color }} className={styles.value}>
        {filter.value}
      </div>
      {!disabled ? (
        <IonIcon
          style={{ color: scssProps?.color }}
          className={styles.icon}
          icon={closeCircle}
          slot="end"
        />
      ) : undefined}
    </IonItem>
  );
};
export default QueryItem;
