/* insights-filter.ts */

export const defaultCohort = (cohort: string): string => {
  return cohort === "Full sample" ? "" : cohort;
};

export const defaultStartDate = (
  year: string | undefined,
  initialYear: string
) => {
  return year === undefined ? `${initialYear}-01-01` : `${year}-01-01`;
};

export const defaultEndDate = (
  year: string | undefined,
  initialYear: string
) => {
  return year === undefined ? `${initialYear}-12-31` : `${year}-12-31`;
};
