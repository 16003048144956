/* React */
import React from "react";

/* Ionic */
import { IonModal } from "@ionic/react";
/* Utils */
import ModalWrapper from "src/utils/wrapper/modal-wrapper/ModalWrapper";

/* Component */
import Legend from "src/components/insights/charts/legend/Legend";

/* Stylesheet */
import styles from "./ExpandModal.module.scss";

/* Interfaces */
import { LegendProps } from "src/shared/interface/charts/Legend";

interface ExpandModalProps {
  showModal: boolean;
  handleModal: (value: boolean) => void;
  chart: any;
  legend: LegendProps[] | undefined;
}

const ExpandModal: React.FC<ExpandModalProps> = ({
  showModal,
  handleModal,
  chart,
  legend,
}) => {
  return (
    <IonModal
      className={styles.expand_modal}
      isOpen={showModal}
      onDidDismiss={() => handleModal(false)}
    >
      <ModalWrapper
        onClick={() => {
          handleModal(false);
        }}
      >
        <div className={styles.content_container}>
          <div className={styles.chart}>{chart}</div>
          <div className={styles.legend_container}>
            {legend ? <Legend legend={legend} /> : undefined}
          </div>
        </div>
      </ModalWrapper>
    </IonModal>
  );
};

export default ExpandModal;
