/* cookies.ts */

/* Cookies */
import Cookies from "js-cookie";

/* Axios */
import { AxiosResponse } from "axios";

/* Utils */
import { convertSecondsToDays } from "src/utils/helper/time";

/* interface */
import { TokenResponse } from "src/shared/interface/api/Token";
import { AuthProps } from "src/shared/interface/common/Auth";

const computeExpirationTime = (time: number) => {
  return Date.now() + time * 1000;
};

export const createCookies = (
  response: AxiosResponse<TokenResponse>
): AuthProps => {
  Cookies.set("access_token", response.data.access_token, {
    expires: convertSecondsToDays(response.data.expires_in),
  });
  Cookies.set("refresh_token", response.data.refresh_token, {
    expires: convertSecondsToDays(response.data.refresh_expires_in),
  });

  return {
    isAuthenticated: true,
    expirationTime: computeExpirationTime(response.data.expires_in),
  };
};
