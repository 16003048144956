/* React */
import React, { useRef } from "react";

/* Ionic */
import { person } from "ionicons/icons";

/* React-Redux */
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store";

/* Plotly */
import Plot from "react-plotly.js";

/* Utils */
import { config, histogramLayout } from "src/utils/config/chart-config";
import { createHistogramLayout } from "src/utils/helper/chart-helper";
import { createLegendInformation } from "src/utils/helper/legend";

/* Component */
import ChartWrapper from "src/utils/wrapper/chart-wrapper/ChartWrapper";

/* Stylesheet */
import styles from "./Histogram.module.scss";

/* Interfaces */
import {
  ChartType,
  BaseChartProps,
} from "src/shared/interface/charts/BaseChart";

import { HistogramProps } from "src/shared/interface/charts/Histogram";
import { ChartLabelProps } from "src/shared/interface/common/ChartLabel";

const Histogram: React.FC<{
  index: number;
  stacked: boolean;
  data: BaseChartProps<HistogramProps[]>;
}> = ({ index, stacked, data }) => {
  const ref = useRef<HTMLDivElement | null>(null);

  // insightsFilterState
  const insightsFilterState = useSelector(
    (state: RootState) => state.insightsFilter
  );

  const { features } = insightsFilterState;

  let min = 0;
  let max = 0;

  const histogramData = data.data
    .filter((data: HistogramProps) => data.type === ChartType.HISTOGRAM)
    .map((data: HistogramProps, index: number) => {
      min = Math.min(...data.x!);
      max = Math.max(...data.x!);
      return {
        ...data,
        name: data.name ? data.name : "Full sample",
        ...createHistogramLayout(index),
      };
    });

  const histogram = (
    <div ref={ref}>
      <Plot
        className={styles.histogram}
        data={stacked ? histogramData : [histogramData[index]]}
        config={config}
        layout={{
          ...histogramLayout,
          xaxis: {
            ...histogramLayout.xaxis,
            range: [min, max],
          },
        }}
        useResizeHandler={true}
      />
    </div>
  );

  const chartLabel: ChartLabelProps = {
    label: { icon: person, total: data.total!, name: "Patients" },
    featureOrCohort: { name: features.value! },
    chart: histogram,
    legend: createLegendInformation(histogramData),
  };

  return <ChartWrapper label={chartLabel}>{histogram}</ChartWrapper>;
};

export default Histogram;
