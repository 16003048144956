/* React */
import React from "react";

/* Ionic */
import { IonInput, IonIcon } from "@ionic/react";

/* React-Hook-Form */
import {
  FieldErrors,
  FieldValues,
  UseFormClearErrors,
  UseFormRegister,
} from "react-hook-form";

/* Stylesheet */
import styles from "./PrimaryInput.module.scss";

/* interface */
interface PrimaryInputProps {
  register?: ReturnType<UseFormRegister<FieldValues>>;
  errors?: FieldErrors<FieldValues>;
  clearErrors?: UseFormClearErrors<FieldValues>;
  type: "text" | "password" | "number";
  name: string;
  placeholder: string;
  value: string | number;
  icon: string;
  onChange: (name: string, value: string) => void;
}

const PrimaryInput: React.FC<PrimaryInputProps> = ({
  register,
  errors,
  clearErrors,
  type,
  name,
  placeholder,
  value,
  icon,
  onChange,
}) => {
  return (
    <React.Fragment>
      <div
        style={{
          margin: errors?.[name] ? "12px 0px 12px 0px" : "18px 0px 18px 0px",
        }}
        className={styles.primary_input}
      >
        <div className={styles.icon_container}>
          <IonIcon
            style={{
              color: errors?.[name] ? "var(--ion-color-error)" : undefined,
            }}
            className={styles.icon}
            icon={icon}
          />
        </div>
        <IonInput
          {...register}
          key={name}
          autocomplete="off"
          aria-label={placeholder}
          style={{
            color: errors?.[name] ? "var(--ion-color-error)" : undefined,
          }}
          className={`${styles.input} input`}
          type={type}
          placeholder={placeholder}
          value={value}
          onIonInput={(e: CustomEvent) => {
            const inputValue = e.detail?.value || "";
            onChange(name, inputValue);
            clearErrors!(name);
          }}
          onIonChange={() => clearErrors!(name)}
        />
      </div>
      {errors?.[name] && (
        <div className={styles.error_message}>
          {errors[name]?.message as React.ReactNode}
        </div>
      )}
    </React.Fragment>
  );
};

export default PrimaryInput;
