/* React */
import React, { useRef } from "react";

/* Ionic */
import { person } from "ionicons/icons";

/* React-Redux */
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store";

/* Plotly */
import Plot from "react-plotly.js";

/* Utils */
import { config, barchartLayout } from "src/utils/config/chart-config";
import { createBarchartLayout } from "src/utils/helper/chart-helper";
import { createLegendInformation } from "src/utils/helper/legend";

/* Component */
import ChartWrapper from "src/utils/wrapper/chart-wrapper/ChartWrapper";

/* Stylesheet */
import styles from "./Barchart.module.scss";

/* Interfaces */
import { ChartType } from "src/shared/interface/charts/BaseChart";
import { BaseChartProps } from "src/shared/interface/charts/BaseChart";
import { BarchartProps } from "src/shared/interface/charts/Barchart";
import { ChartLabelProps } from "src/shared/interface/common/ChartLabel";

const Barchart: React.FC<{
  index: number;
  stacked: boolean;
  data: BaseChartProps<BarchartProps[]>;
}> = ({ index, stacked, data }) => {
  const ref = useRef<HTMLDivElement | null>(null);

  // insightsFilterState
  const insightsFilterState = useSelector(
    (state: RootState) => state.insightsFilter
  );

  const { features } = insightsFilterState;

  // barchartData
  const barchartData = data.data
    .filter((data: BarchartProps) => data.type === ChartType.BAR)
    .map((data: BarchartProps, index: number) => {
      return {
        ...data,
        name: data.name ? data.name : "Full sample",
        ...createBarchartLayout(index),
      };
    });

  const barchart = (
    <div ref={ref}>
      <Plot
        className={styles.barchart}
        data={stacked ? barchartData : [barchartData[index]]}
        config={config}
        layout={barchartLayout}
        useResizeHandler={true}
      />
    </div>
  );

  const chartLabel: ChartLabelProps = {
    label: { icon: person, total: data.total!, name: "Patients" },
    featureOrCohort: { name: features.value! },
    chart: barchart,
    legend: createLegendInformation(barchartData),
  };

  return <ChartWrapper label={chartLabel}>{barchart}</ChartWrapper>;
};

export default Barchart;
