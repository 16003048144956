/* insightsFilterThunks.ts */

/* Redux-Toolkit */
import { createAsyncThunk } from "@reduxjs/toolkit";

/* Axios */
import axios from "axios";

/* Luxon */
import { DateTime } from "luxon";

/* Utils */
import axiosInterceptor from "src/utils/helper/axios-interceptor";

/* Interfaces */
import { BaseErrorProps } from "src/shared/interface/common/Error";
import {
  FilterResponseCategorical,
  FilterResponseNumeric,
} from "src/shared/interface/api/Filters";

export const fetchInsightsFilterCategorical = createAsyncThunk<
  FilterResponseCategorical,
  string,
  { rejectValue: BaseErrorProps }
>(
  "insightsFilter/fetchInsightsFilterCategorical",
  async (mainCondition: string, { rejectWithValue }) => {
    try {
      const response = await axiosInterceptor.get("/options/filters", {
        params: {
          main_condition: mainCondition,
        },
      });

      return {
        features: response.data.features,
        cohorts: response.data.cohorts,
      };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const message: string = error.response?.data.detail;
        return rejectWithValue({ showError: true, message: message });
      }
      return rejectWithValue({
        showError: true,
        message: "An unexpected error occurred",
      });
    }
  }
);

export const fetchInsightsFilterNumeric = createAsyncThunk<
  FilterResponseNumeric,
  string,
  { rejectValue: BaseErrorProps }
>(
  "insightsFilter/fetchInsightsFilterNumeric",
  async (mainCondition: string) => {
    const response = await axiosInterceptor.get("/options/filters", {
      params: {
        main_condition: mainCondition,
      },
    });

    const firstYearString = DateTime.fromISO(response.data.first_year).toFormat(
      "yyyy"
    );

    const lastYearString = DateTime.fromISO(response.data.last_year).toFormat(
      "yyyy"
    );

    return {
      firstYear: firstYearString,
      lastYear: lastYearString,
    };
  }
);
