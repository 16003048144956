/* React */
import React from "react";

/* React-Redux */
import { useDispatch, useSelector } from "react-redux";
import { setMainCondition } from "src/redux/features/insights/mainConditionSlice";
import { setInsightsFilterValue } from "src/redux/features/insights/insights-filter/insightsFilterSlice";
import { RootState } from "src/redux/store";

/* Component */
import QueryItem from "src/components/insights/query-list-container/query-item/QueryItem";

/* Stylesheet */
import styles from "./QueryList.module.scss";

/* Interfaces */
import { InsightsFilterProps } from "src/shared/interface/common/InsightsFilter";

interface QueryListProps {
  scssProps?: ScssProps;
}

interface ScssProps {
  display?: string;
  color?: string;
  backgroundColor?: string;
}

const QueryList: React.FC<QueryListProps> = ({ scssProps }) => {
  const dispatch = useDispatch();

  // mainConditionState
  const mainConditionState = useSelector(
    (state: RootState) => state.mainCondition
  );
  const { initialValue } = mainConditionState;

  // insightsFilterState
  const insightsFilterState = useSelector(
    (state: RootState) => state.insightsFilter
  );

  return (
    <div style={{ display: scssProps?.display }} className={styles.query_list}>
      <QueryItem
        scssProps={{
          color: scssProps?.color,
          backgroundColor: scssProps?.backgroundColor,
        }}
        filter={mainConditionState}
        onClick={() => dispatch(setMainCondition(initialValue!))}
      />
      {Object.keys(insightsFilterState).map((key: string, index: number) => {
        const typedKey = key as keyof InsightsFilterProps;
        return (
          <QueryItem
            key={index}
            scssProps={{
              color: scssProps?.color,
              backgroundColor: scssProps?.backgroundColor,
            }}
            filter={insightsFilterState[typedKey]}
            onClick={() =>
              dispatch(
                setInsightsFilterValue({
                  key: typedKey,
                  value: insightsFilterState[typedKey].initialValue!,
                })
              )
            }
          />
        );
      })}
    </div>
  );
};

export default QueryList;
