/* useCredentials.ts */

/* React */
import { useRef, useState } from "react";

/* Interface */
interface UseCredentialsProps<T> {
  initialCredentials: T;
  setValue?: (name: string, value: string | number) => void;
  trigger?: (name: string) => void;
  debounceTime?: number;
}

const useCredentials = <T>({
  initialCredentials,
  setValue,
  trigger,
  debounceTime = 750,
}: UseCredentialsProps<T>) => {
  const debounceRef = useRef<NodeJS.Timeout | null>(null);

  // credentials
  const [credentials, setCredentials] = useState<T>(initialCredentials);

  const handleCredentials = (name: string, value: string | number) => {
    setCredentials({ ...credentials, [name]: value });

    if (setValue) {
      setValue(name, value);
    }

    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }

    debounceRef.current = setTimeout(() => {
      if (trigger) {
        trigger(name);
      }
    }, debounceTime);
  };

  return { credentials, handleCredentials };
};

export default useCredentials;
