/* React */
import React from "react";

/* Ionic */
import { IonItem, IonCheckbox, IonLabel } from "@ionic/react";

/* Stylesheet */
import styles from "./ConfidenceInterval.module.scss";

/* Interface */
interface ConfidenceIntervalProps {
  showConfidenceIntervals: boolean;
  handleItemClick: () => void;
  handleCheckboxChange: (e: CustomEvent) => void;
}

const ConfidenceInterval: React.FC<ConfidenceIntervalProps> = ({
  showConfidenceIntervals,
  handleItemClick,
  handleCheckboxChange,
}) => {
  return (
    <IonItem
      className={styles.checkbox_item}
      lines="none"
      button
      onClick={handleItemClick}
    >
      <IonCheckbox
        className={styles.checkbox}
        checked={showConfidenceIntervals}
        slot="start"
        aria-label="Checkbox"
        onIonChange={handleCheckboxChange}
      />
      <IonLabel className={styles.label}>
        <div>Show 95% confidence intervals</div>
      </IonLabel>
    </IonItem>
  );
};

export default ConfidenceInterval;
