/* chart-helper.ts */

/* Utils */
import {
  colorMapper,
  treatmentColorMapper,
} from "src/utils/helper/color-mapper";

/* Interface */
import {
  SankeyBoxplotProps,
  SankeyProps,
} from "src/shared/interface/common/BaseChart";

import {
  CIProps,
  SurvivalPlotProps,
} from "src/shared/interface/charts/SurvivalPlot";

export const createHoverLabel = (
  index: number,
  colorMapper: (index: number) => string
) => {
  return {
    bgcolor: colorMapper(index),
    font: {
      color: "#ffffff",
    },
    bordercolor: "transparent",
  };
};

/* Barchart */
export const createBarchartLayout = (index: number) => {
  return {
    marker: {
      color: colorMapper(index),
    },
    hovertemplate: `<b>Group:</b> %{x}<br><b>Value:</b> %{y}<extra></extra>`,
    hoverlabel: createHoverLabel(index, () => colorMapper(index)),
  };
};

/* Histogram */
export const createHistogramLayout = (index: number) => {
  return {
    marker: {
      color: colorMapper(index),
    },
    hovertemplate: `<b>Group:</b> %{x}<br><b>Value:</b> %{y}<extra></extra>`,
    hoverlabel: createHoverLabel(index, () => colorMapper(index)),
  };
};

/* Boxplot */
export const createBoxplotLayout = (index: number) => {
  return {
    orientation: "h" as "h" | "v",
    marker: {
      color: colorMapper(index),
    },
    hovertemplate: `%{x}<extra></extra>`,
    hoverlabel: {
      bgcolor: colorMapper(index),
      font: {
        color: "#fff",
      },
      bordercolor: "transparent",
    },
  };
};

/* Survival Plot */
export const createSurvivalPlotLayout = (
  index: number,
  data: SurvivalPlotProps,
  showConfidenceIntervals: boolean
) => {
  return {
    ...data,
    trace: {
      ...data.trace,
      x: data.trace.x.map((x) => x / 30.44).filter((x) => x >= 0),
      y: data.trace.y,
      mode: "lines",
      line: { shape: "hv" },
      marker: { color: colorMapper(index) },
      hovertemplate: `<b>Time:</b> %{x:.2f} mth<br><b>Event:</b> %{y}<extra></extra>`,
      hoverlabel: createHoverLabel(index, () => colorMapper(index)),
    },
    censored: {
      ...data.censored,
      x: data.censored.x.map((x) => x / 30.44).filter((x) => x >= 0),
      y: data.censored.y,
      mode: "text",
      text: "|",
      textfont: {
        color: colorMapper(index),
      },
      hovertemplate: `<b>Time:</b> %{x:.2f} mth<br><b>Event:</b> %{y}<extra></extra>`,
      hoverlabel: createHoverLabel(index, () => colorMapper(index)),
    },
    ciUpper: showConfidenceIntervals
      ? {
          x: (data.ciUpper as CIProps).x
            .map((x) => x / 30.44)
            .filter((x) => x >= 0),
          y: (data.ciUpper as CIProps)?.y,
          mode: "lines",
          line: { width: 0 },
          fill: "tonexty",
          fillcolor: `${colorMapper(index)}30`,
          hovertemplate: `<b>Upper:</b> %{y:.2f}<extra></extra>`,
          hoverlabel: createHoverLabel(index, () => colorMapper(index)),
        }
      : {},
    ciLower: showConfidenceIntervals
      ? {
          ...data.ciLower,
          x:
            (data.ciLower as CIProps).x
              .map((x: number) => x / 30.44)
              .filter((x: number) => x >= 0) || [],
          y: (data.ciLower as CIProps)?.y,
          mode: "lines",
          marker: { color: "transparent" },
          fill: "tonexty",
          fillcolor: `${colorMapper(index)}30`,
          hovertemplate: `<b>Lower:</b> %{y:.2f}<extra></extra>`,
          hoverlabel: createHoverLabel(index, () => colorMapper(index)),
        }
      : {},
  };
};

/* Sankey Diagram */
export const createSankeyDiagramLayout = (data: SankeyProps) => {
  return {
    ...data,
    node: {
      ...data.node,
      color: data.node.label.map((_, index) => {
        const color = treatmentColorMapper(index, data.node.label);
        return color;
      }),
      pad: 15,
      thickness: 20,
      line: {
        color: "transparent",
      },
      hovertemplate: `<b>Node:</b> %{label}<extra></extra>`,
      hoverlabel: {
        bgcolor: data.node.label.map((_, index) => {
          const color = treatmentColorMapper(index, data.node.label);
          return color;
        }),
        font: {
          color: "#ffffff",
        },
        bordercolor: "transparent",
      },
    },
    link: {
      ...data.link,
      color: data.link.source.map((sourceIndex) => {
        const color = treatmentColorMapper(sourceIndex, data.node.label);
        return `${color}AA`;
      }),
      hovertemplate: `<b>Source:</b> %{source.label}<br /><b>Target:</b> %{target.label} <br /><b>Value:</b> %{value}<extra></extra>`,
      hoverlabel: {
        bgcolor: data.node.label.map((_, index) => {
          const color = treatmentColorMapper(index, data.node.label);
          return color;
        }),
        font: {
          color: "#ffffff",
        },
        bordercolor: "transparent",
      },
    },
  };
};

/* Sankey Boxplot */
export const createSankeyBoxplotLayout = (data: SankeyBoxplotProps) => {
  return {
    ...data,
    data: [
      ...data.data.map((data, index) => {
        return {
          ...data,
          marker: {
            color: treatmentColorMapper(index, data.name),
          },
          hoverlabel: {
            bgcolor: treatmentColorMapper(index, data.name),
            font: {
              color: "#ffffff",
            },
            bordercolor: "transparent",
          },
        };
      }),
    ],
  };
};
