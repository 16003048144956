/* React */
import React, { useEffect } from "react";

/* Ionic */
import { IonToast } from "@ionic/react";
import { ban } from "ionicons/icons";

/* React-Redux */
import { useDispatch } from "react-redux";
import { UnknownAction } from "@reduxjs/toolkit";

/* Stylesheet */
import styles from "./ToastMessage.module.scss";

/* Interface(s) */
import { ErrorType } from "src/shared/interface/common/Error";

interface ToastMessageProps {
  isOpen: boolean;
  message: string;
  level: ErrorType;
  clearError?: () => UnknownAction;
}

const ToastMessage: React.FC<ToastMessageProps> = ({
  isOpen,
  message,
  level,
  clearError,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const timer = setTimeout(() => {
      if (clearError) {
        dispatch(clearError());
      }
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [dispatch, clearError]);

  return (
    <IonToast
      style={{
        "--background":
          level === ErrorType.ERROR ? "var(--ion-color-error)" : undefined,
      }}
      className={styles.toast_message}
      isOpen={isOpen}
      message={message}
      duration={5000}
      icon={level === ErrorType.ERROR ? ban : undefined}
    />
  );
};

export default ToastMessage;
