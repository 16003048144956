/* React */
import React, { useEffect } from "react";

/* React-Redux */
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "src/redux/store";
// import { clearError } from "src/redux/features/insights/descriptiveStatisticsSlice";

/* Thunk */
import { fetchDescriptiveStatistics } from "src/redux/thunks/descriptive-statistics/descriptiveStatisticsThunk";

/* Utils */
import useChartLoadingScreen from "src/utils/hooks/useChartLoadingScreen";
import ChartLayoutWrapper from "src/utils/wrapper/chart-layout-wrapper/ChartLayoutWrapper";
import { basicLayout } from "src/utils/layout/basicLayout";

/* Interface */
import { LayoutProps } from "src/shared/interface/common/Layout";
// import { ErrorType } from "src/shared/interface/common/Error";

const DescriptiveStatistics: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  // mainConditionState
  const mainConditionState = useSelector(
    (state: RootState) => state.mainCondition
  );

  // insightsFilterState
  const insightsFilterState = useSelector(
    (state: RootState) => state.insightsFilter
  );

  // descriptiveStatisticsState
  const descriptiveStatisticsState = useSelector(
    (state: RootState) => state.descriptiveStatistics
  );

  const { data, isFetching, isUpdating } = descriptiveStatisticsState;

  useChartLoadingScreen(isFetching!, isUpdating!);

  useEffect(() => {
    if (mainConditionState.value) {
      dispatch(
        fetchDescriptiveStatistics({
          mainCondition: mainConditionState.value,
          filters: {
            feature: insightsFilterState.features.value!,
            cohort: insightsFilterState.cohorts.value!,
            startDate: insightsFilterState.firstYear,
            endDate: insightsFilterState.lastYear,
          },
        })
      );
    }
  }, [dispatch, mainConditionState.value, insightsFilterState]);

  const types = data?.types as string[];

  const layout: LayoutProps = basicLayout(types, true, data!);

  return (
    <React.Fragment>
      <ChartLayoutWrapper layout={layout} />
      {/* {error ? (
        <ToastMessage
          isOpen={error.showError}
          message={error.message!}
          level={ErrorType.ERROR}
        />
      ) : undefined} */}
    </React.Fragment>
  );
};

export default DescriptiveStatistics;
