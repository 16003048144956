/* React */
import React, { useState } from "react";

/* Swiper */
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";

/* Utils */
import { createSankeyBoxplotLayout } from "src/utils/helper/chart-helper";
import { removeEmptyBoxplot } from "src/utils/chart/boxplot";

/* Components */
import SwiperControls from "src/components/shared/swiper/swiper-controls/SwiperControls";
import Boxplot from "src/components/insights/charts/sankey-diagram/boxplot/Boxplot";

/* Stylesheet */
import styles from "./BoxplotSwiper.module.scss";

/* Interfaces */
import { SankeyBoxplotProps } from "src/shared/interface/charts/SankeyDiagram";

interface BoxplotSwiperProps {
  data: SankeyBoxplotProps[];
}

const BoxplotContainer: React.FC<BoxplotSwiperProps> = ({ data }) => {
  // boxplotData
  const boxplotData: SankeyBoxplotProps[] = data.map(
    (data: SankeyBoxplotProps) => {
      return {
        ...createSankeyBoxplotLayout(data),
      };
    }
  );

  // activeIndex
  const [activeIndex, setActiveIndex] = useState<number>(0);

  // swiperInstance
  const [swiperInstance, setSwiperInstance] = useState<SwiperClass | null>(
    null
  );

  const filteredData: SankeyBoxplotProps[] = removeEmptyBoxplot(boxplotData);

  return (
    <div className={styles.boxplot_swiper}>
      <Swiper
        onSwiper={setSwiperInstance}
        onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
      >
        {filteredData.map((data: SankeyBoxplotProps, index: number) => {
          return (
            <SwiperSlide style={{ height: "50%" }} key={index}>
              <Boxplot data={data} />
            </SwiperSlide>
          );
        })}
      </Swiper>
      <SwiperControls
        scssProps={{
          padding: "12px 0px 12px 0px",
        }}
        stacked={true}
        swiperInstance={swiperInstance}
        length={filteredData.length}
        activeIndex={activeIndex}
        name={undefined}
      />
    </div>
  );
};

export default BoxplotContainer;
