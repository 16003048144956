/* React */
import React from "react";

/* Ionic */
import { IonGrid, IonRow, IonCol } from "@ionic/react";

/* Components */
import Cohorts from "src/components/insights/charts/legend/cohorts/Cohorts";
import MinimalPieChart from "src/components/insights/charts/legend/minimal-pie-chart/MinimalPieChart";

/* Stylesheet */
import styles from "./Legend.module.scss";

/* Interface */
import { LegendProps } from "src/shared/interface/charts/Legend";

const Legend: React.FC<{ legend: LegendProps[] }> = ({ legend }) => {
  return (
    <IonGrid className={styles.legend}>
      <IonRow className={styles.row}>
        <IonCol className={`${styles.col} ${styles.cohorts_col}`} size="6">
          <Cohorts legend={legend} />
        </IonCol>
        <IonCol className={`${styles.col} ${styles.chart_col}`} size="6">
          <MinimalPieChart legend={legend} />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default Legend;
