/* stats-container.ts */

/* Interface */
import { StatsProps } from "src/shared/interface/charts/Boxplot";

export const createHorizontalBarchart = (data: StatsProps) => {
  return {
    type: "bar",
    x: Object.values(data),
    y: Object.keys(data),
    orientation: "h",
  };
};

const transformWhiskerHighLow = (value: string) => {
  const withoutUnderscore = value.replace(/_/g, " ").split(" ");
  if (withoutUnderscore.length > 1) {
    withoutUnderscore[1] = `(${withoutUnderscore[1]})`;
  }
  return withoutUnderscore.join(" ");
};

export const statsTransformed = (stats: StatsProps) => {
  const transformed = Object.entries(stats).map((stats) => {
    return {
      key: transformWhiskerHighLow(stats[0]),
      value: Math.round(stats[1]),
    };
  });
  return transformed;
};
