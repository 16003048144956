/* React */
import React from "react";

/* Components */
import TotalContainer from "src/components/insights/charts/chart-toolbar/total-container/TotalContainer";
import Options from "src/components/insights/charts/chart-toolbar/options/Options";

/* Stylesheet */
import styles from "./ChartToolbar.module.scss";

/* Interface */
import { ChartLabelProps } from "src/shared/interface/common/ChartLabel";

interface ScssProps {
  margin?: string;
}

const ChartToolbar: React.FC<{
  scssProps?: ScssProps;
  label?: ChartLabelProps;
  component?: any;
  handleModal: (value: boolean) => void;
}> = ({ scssProps, label, component, handleModal }) => {
  return (
    <div style={{ margin: scssProps?.margin }} className={styles.chart_toolbar}>
      <TotalContainer label={label} />
      <div className={styles.feature}>{label?.featureOrCohort?.name}</div>
      <Options handleModal={handleModal} component={component} />
    </div>
  );
};

export default ChartToolbar;
