/* React */
import React from "react";

/* Ionic */
import { IonItem, IonLabel } from "@ionic/react";

/* Stylesheet */
import styles from "./SelectItem.module.scss";

const SelectItem: React.FC<{
  itemKey: string;
  item: string;
  handleShowValues: (value: boolean) => void;
  handleValue: (key: string, value: string) => void;
}> = ({ itemKey, item, handleShowValues, handleValue }) => {
  return (
    <IonItem
      className={styles.select_item}
      lines="none"
      button
      onClick={() => {
        handleShowValues(false);
        handleValue(itemKey, item);
      }}
    >
      <IonLabel className={styles.label}>{item}</IonLabel>
    </IonItem>
  );
};

export default SelectItem;
