/* React */
import React, { useRef, useState, useEffect } from "react";

/* Ionic */
import { IonIcon, IonItem } from "@ionic/react";
import { chevronDown, chevronUp } from "ionicons/icons";

/* Hook */
import useClickOutsideHandler from "src/utils/hooks/useClickOutsideHandler";

/* Components */
import FilterLabel from "src/components/insights/insights-filter/filter-label/FilterLabel";
import ValueContainer from "src/components/shared/simple-select-container/value-container/ValueContainer";

/* Stylesheet */
import styles from "./SimpleSelectContainer.module.scss";

/* Interface */
import { FilterProps } from "src/shared/interface/common/InsightsFilter";

interface SimpleSelectContainerProps {
  label?: string;
  item: FilterProps<string | undefined>;
  handleItem: (item: string) => void;
}

const SimpleSelectContainer: React.FC<SimpleSelectContainerProps> = ({
  label,
  item,
  handleItem,
}) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  // showValues
  const [showValues, setShowValues] = useState<boolean>(false);

  // searchTerm
  const [searchTerm, setSearchTerm] = useState<string>("");

  const handleShowValues = (value: boolean) => {
    setShowValues(value);
  };

  useClickOutsideHandler({ ref: dropdownRef, onClick: handleShowValues });

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      setSearchTerm((prev: string) => prev + event.key.toLowerCase());

      // Clear the previous timeout if it exists
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        setSearchTerm("");
      }, 3000);
    };

    window.addEventListener("keypress", handleKeyPress);

    return () => {
      window.removeEventListener("keypress", handleKeyPress);
      // Clear timeout on cleanup
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  // Remove already selected items
  const itemFilter = (value: string, items: string[]) => {
    return items.filter((item) => item !== value);
  };

  // Filter items based on search term
  const itemsFiltered = item.items?.filter((item) => {
    return item.toLowerCase().startsWith(searchTerm);
  });

  return (
    <div ref={dropdownRef} className={styles.simple_select_container}>
      {label ? (
        <FilterLabel
          scssProps={{ margin: "0px 0px 21px 8px" }}
          label={label!}
        />
      ) : undefined}
      <div className={styles.wrapper_container}>
        <IonItem
          style={{
            "--background": showValues
              ? "var(--ion-color-secondary)"
              : undefined,
            borderRadius: showValues ? "8px 8px 0px 0px" : "42px",
          }}
          className={styles.select_item}
          lines="none"
          button
          onClick={() => handleShowValues(!showValues)}
        >
          <div className={styles.label}>{item.value}</div>
          <IonIcon
            style={{ color: showValues ? "#ffffff" : undefined }}
            className={styles.icon}
            icon={showValues ? chevronUp : chevronDown}
            slot="end"
          />
        </IonItem>
        {showValues ? (
          <ValueContainer
            items={itemFilter(item.value!, itemsFiltered!)}
            handleItem={handleItem}
            handleValues={handleShowValues}
          />
        ) : undefined}
      </div>
    </div>
  );
};

export default SimpleSelectContainer;
