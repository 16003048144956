/* sankeyDiagramOptionsSlice.ts */

/* Redux-Toolkit */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

/* Interface */
import { SankeyDiagramOptionsProps } from "src/shared/interface/options/SankeyDiagramOptions";

const initialState: SankeyDiagramOptionsProps = {
  deepestFLow: 0,
  maxDepth: 2,
  minWidth: 0.1,
};

export const sankeyDiagramOptionsSlice = createSlice({
  name: "sankeyDiagramOptions",
  initialState,
  reducers: {
    setDeepestFlow: (state, action: PayloadAction<number>) => {
      state.deepestFLow = action.payload;
    },
    setMaxDepth: (state, action: PayloadAction<number>) => {
      state.maxDepth = action.payload;
    },
    setMinWidth: (state, action: PayloadAction<number>) => {
      state.minWidth = action.payload;
    },
  },
});

/* Actions */
export const { setDeepestFlow, setMaxDepth, setMinWidth } =
  sankeyDiagramOptionsSlice.actions;

/* Reducer */
export default sankeyDiagramOptionsSlice.reducer;
