/* React */
import React, { useState, useEffect } from "react";

/* Ionic */
import { IonBackdrop } from "@ionic/react";
import { close } from "ionicons/icons";

/* Component(s) */
import Avatar from "src/components/menus/user-menu/avatar/Avatar";
import NavItem from "src/components/shared/nav-item/NavItem";
import Logout from "src/components/menus/user-menu/logout/Logout";
import PrimaryFabButton from "src/components/shared/buttons/primary-fab-button/PrimaryFabButton";

/* Constants */
import { USER_MENU_NAV_ITEMS } from "src/shared/constants/NavItems";

/* Stylesheet */
import styles from "./UserMenu.module.scss";

/* Interface(s) */
import { NavItemProps } from "src/shared/interface/common/NavItem";

const UserMenu: React.FC = () => {
  const navItems = USER_MENU_NAV_ITEMS;

  // showMenu
  const [showMenu, setShowMenu] = useState<boolean>(false);

  const handleShowMenu = (value: boolean) => {
    setShowMenu(value);
  };

  useEffect(() => {
    const keyDownHandler = () => {
      handleShowMenu(false);
    };
    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);

  return (
    <div className={styles.user_menu}>
      {showMenu ? (
        <IonBackdrop
          className={styles.backdrop}
          tappable
          onIonBackdropTap={() => setShowMenu(false)}
        />
      ) : undefined}
      <Avatar onClick={() => setShowMenu(true)} />
      {showMenu ? (
        <div data-testid="user-menu" className={styles.menu_container}>
          <PrimaryFabButton
            scssProps={{
              position: "absolute",
              top: 10,
              right: 10,
              background: "transparent",
            }}
            icon={close}
            ariaLabel={"Close"}
            onClick={() => setShowMenu(false)}
          />
          <div className={styles.wrapper_container}>
            {navItems.map((item: NavItemProps, index: number) => {
              return (
                <NavItem
                  key={index}
                  item={item}
                  showLines={true}
                  showName={true}
                  disabled={true}
                  scssProps={{ borderRadius: "4px" }}
                />
              );
            })}
            <Logout />
          </div>
        </div>
      ) : undefined}
    </div>
  );
};

export default UserMenu;
