/**
 * @file LoadingScreen.tsx
 *
 * @description
 * A loading screen component that displays a loading bar when the isLoading state is true.
 * On initial render, when the data is undefined, no content will be shown and the loading screen is shown for a minimum of 500ms.
 * If data is defined and transformations are done, a different loading screen will be shown after a certain time.
 *
 */

/* React */
import React, { useEffect } from "react";

/* React-Redux */
import { useDispatch, useSelector } from "react-redux";
import { setShowContent } from "src/redux/features/loading-screen/loadingScreenSlice";
import { RootState } from "src/redux/store";

/* Stylesheet */
import styles from "./LoadingScreen.module.scss";

const LoadingBarExample: React.FC = () => {
  const dispatch = useDispatch();

  // isLoading
  const loadingScreenState = useSelector(
    (state: RootState) => state.loadingScreen
  );

  const { isFetching, isUpdating, showContent } = loadingScreenState;

  useEffect(() => {
    if (isFetching) {
      dispatch(setShowContent(false));
    } else {
      const timer = setTimeout(() => {
        dispatch(setShowContent(true));
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [dispatch, isFetching, showContent]);

  return isFetching !== undefined && !showContent ? (
    <div className={styles.loading_screen}>
      <div className={styles.loading_bar} />
    </div>
  ) : isUpdating ? (
    /* There might be a better solution in the future, but for now it's totally fine to have somehow duplicate code */
    <div className={styles.updating_screen}>
      <div className={styles.updating_bar} />
    </div>
  ) : undefined;
};

export default LoadingBarExample;
