/* React */
import React from "react";

/* Ionic */
import { IonFabButton, IonIcon } from "@ionic/react";

/* Stylesheet */
import styles from "./PrimaryFabButton.module.scss";

/* interface */
interface PrimaryFabButtonProps {
  scssProps?: ScssProps;
  ariaLabel: string;
  icon: string;
  disabled?: boolean;
  onClick?: () => void;
}

interface ScssProps {
  margin?: string;
  position?: "relative" | "absolute" | "static";
  height?: string;
  width?: string;
  top?: number;
  left?: number;
  right?: number;
  bottom?: number;
  color?: string;
  background?: string;
  zIndex?: number;
}

const PrimaryFabButton: React.FC<PrimaryFabButtonProps> = ({
  scssProps,
  ariaLabel,
  icon,
  disabled,
  onClick,
}) => {
  return (
    <IonFabButton
      style={{
        margin: scssProps?.margin,
        position: scssProps?.position,
        height: scssProps?.height,
        width: scssProps?.width,
        top: scssProps?.top,
        left: scssProps?.left,
        right: scssProps?.right,
        bottom: scssProps?.bottom,
        "--color": scssProps?.color,
        "--background": scssProps?.background,
        zIndex: scssProps?.zIndex,
      }}
      className={styles.primary_fab_button}
      aria-label={ariaLabel}
      size="small"
      disabled={disabled}
      onClick={onClick}
    >
      <IonIcon className={styles.icon} icon={icon} />
    </IonFabButton>
  );
};

export default PrimaryFabButton;
