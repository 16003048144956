/* React */
import React, { useEffect } from "react";

/* React-Redux */
import { useDispatch, useSelector } from "react-redux";
import {
  setDisabled,
  clear,
} from "src/redux/features/insights/insights-filter/insightsFilterSlice";

import { AppDispatch, RootState } from "src/redux/store";
import { clearError } from "src/redux/features/insights/survivalAnalysisSlice";

/* Thunk */
import { fetchSurvivalAnalysis } from "src/redux/thunks/survival-analysis/survivalAnalysisThunk";

/* Utils */
import useChartLoadingScreen from "src/utils/hooks/useChartLoadingScreen";
import ChartLayoutWrapper from "src/utils/wrapper/chart-layout-wrapper/ChartLayoutWrapper";

/* Component */
import ToastMessage from "src/components/shared/toast-message/ToastMessage";

/* Interfaces */
import { FilterType } from "src/shared/interface/common/InsightsFilter";
import { LayoutProps } from "src/shared/interface/common/Layout";
import { ErrorType } from "src/shared/interface/common/Error";
import { basicLayout } from "src/utils/layout/basicLayout";

const SurvivalAnalysis: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const mainConditionState = useSelector(
    (state: RootState) => state.mainCondition
  );
  const insightsFilterState = useSelector(
    (state: RootState) => state.insightsFilter
  );
  const survivalAnalysisState = useSelector(
    (state: RootState) => state.survivalAnalysis
  );

  const survivalPlotOptionsState = useSelector(
    (state: RootState) => state.survivalAnalysisOptions
  );

  const { startPoint } = survivalPlotOptionsState;

  const { data, isFetching, isUpdating, error } = survivalAnalysisState;

  useChartLoadingScreen(isFetching!, isUpdating!);

  useEffect(() => {
    dispatch(setDisabled({ key: FilterType.FEATURES, value: true }));

    return () => {
      dispatch(clear(FilterType.FEATURES));
      dispatch(clearError());
    };
  }, [dispatch]);

  useEffect(() => {
    if (mainConditionState.value) {
      dispatch(
        fetchSurvivalAnalysis({
          mainCondition: mainConditionState.value,
          filters: {
            feature: insightsFilterState.features.value!,
            cohort: insightsFilterState.cohorts.value!,
            startDate: insightsFilterState.firstYear,
            endDate: insightsFilterState.lastYear,
          },
          startPoint: startPoint.value!,
        })
      );
    }
  }, [
    dispatch,
    mainConditionState.value,
    insightsFilterState,
    startPoint.value,
  ]);

  // types
  const types = data?.types as string[];

  const layout: LayoutProps = basicLayout(types, true, data!);

  return (
    <React.Fragment>
      <ChartLayoutWrapper layout={layout} />
      {error ? (
        <ToastMessage
          isOpen={error.showError}
          message={error.message!}
          level={ErrorType.ERROR}
        />
      ) : undefined}
    </React.Fragment>
  );
};

export default SurvivalAnalysis;
