/* React */
import React, { useEffect } from "react";

/* Ionic */
import { IonFabButton, IonIcon } from "@ionic/react";
import { at, logoLinkedin } from "ionicons/icons";

/* React-Redux */
import { useDispatch } from "react-redux";
import { setDisabled } from "src/redux/features/insights/insights-filter/insightsFilterMenuSlice";

/* Utils */
import PageWrapper from "src/utils/wrapper/page-wrapper/PageWrapper";
import PageLayoutWrapper from "src/utils/wrapper/page-layout-wrapper/PageLayoutWrapper";

/* Component */
import Disclaimer from "src/components/shared/disclaimer/Disclaimer";

/* Stylesheet */
import styles from "./Welcome.module.scss";

const Welcome: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setDisabled(true));
  }, [dispatch]);

  return (
    <PageWrapper>
      <PageLayoutWrapper>
        <Disclaimer />
        <div className={styles.info_message}>
          <div className={styles.title}>
            Welcome to <span className={"gradient"}>syndena Insights</span>
          </div>
          <p>
            syndena offers a comprehensive solution that presents structured
            real-world medical data using a consistent and GDPR-compliant data
            model. This ensures high-quality data that can be analysed
            efficently and used collaboratively - both in cooperation with other
            medical institutions and in international research projects. This
            maximises the potential of real-world evidence in medicine and
            advances research into effective treatments and therapies.
          </p>
        </div>
        <div className={styles.social_links}>
          <span>For more information</span>
          <IonFabButton
            className={styles.fab_button}
            arial-label={"Website"}
            size="small"
            onClick={() => (window.location.href = "https://syndena.com/")}
          >
            <IonIcon className={styles.icon} icon={at} />
          </IonFabButton>
          <IonFabButton
            className={styles.fab_button}
            arial-label={"LinkedIn"}
            size="small"
            onClick={() =>
              (window.location.href =
                "https://www.linkedin.com/company/syndena/posts/?feedView=all")
            }
          >
            <IonIcon className={styles.icon} icon={logoLinkedin} />
          </IonFabButton>
        </div>
      </PageLayoutWrapper>
    </PageWrapper>
  );
};

export default Welcome;
