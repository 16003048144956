/* React */
import React from "react";

/* Ionic */
import { IonAvatar } from "@ionic/react";

/* Stylesheet */
import styles from "./Avatar.module.scss";

/* Interface(s) */
interface AvatarProps {
  onClick: () => void;
}

const Avatar: React.FC<AvatarProps> = ({ onClick }) => {
  return (
    <IonAvatar data-testid="avatar" className={styles.avatar} onClick={onClick}>
      <img className={styles.image} src="/assets/img/avatar.svg" alt="avatar" />
    </IonAvatar>
  );
};

export default Avatar;
