/* mainMenuSlice.ts */

/* Redux-Toolkit */
import { createSlice } from "@reduxjs/toolkit";

/* Interface(s) */
import { MainMenuProps } from "src/shared/interface/common/MainMenu";

const initialState: MainMenuProps = {
  disabled: false,
};

export const mainMenuSlice = createSlice({
  name: "mainMenu",
  initialState: initialState,
  reducers: {
    setDisabled: (state, action) => {
      state.disabled = action.payload;
    },
  },
});

/* Action(s) */
export const { setDisabled } = mainMenuSlice.actions;

/* Reducer */
export default mainMenuSlice.reducer;
