/* descriptiveStatisticsThunk.ts */

/* Redux-Toolkit */
import { createAsyncThunk } from "@reduxjs/toolkit";

/* Axios */
import axios from "axios";

/* Utils */
import axiosInterceptor from "src/utils/helper/axios-interceptor";

import {
  defaultCohort,
  defaultStartDate,
  defaultEndDate,
} from "src/utils/helper/insights-filter";

/* Interfaces */
import { BaseChartProps } from "src/shared/interface/charts/BaseChart";
import { BoxplotProps } from "src/shared/interface/charts/Boxplot";
import { BarchartProps } from "src/shared/interface/charts/Barchart";
import { HistogramProps } from "src/shared/interface/charts/Histogram";
import { BaseChartDataRequest } from "src/shared/interface/api/ChartData";
import { BaseErrorProps } from "src/shared/interface/common/Error";
import { BaseFilterRequest } from "src/shared/interface/api/Filters";

export const fetchDescriptiveStatistics = createAsyncThunk<
  BaseChartProps<BarchartProps[] | HistogramProps[] | BoxplotProps[]>,
  BaseChartDataRequest<BaseFilterRequest>,
  { rejectValue: BaseErrorProps }
>(
  "descriptiveStatistics/fetchDescriptiveStatistics",
  async (
    params: BaseChartDataRequest<BaseFilterRequest>,
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInterceptor.get(
        "/data/cohort_data_statistics",
        {
          params: {
            main_condition: params.mainCondition,
            feature: params.filters.feature,
            cohort: defaultCohort(params.filters.cohort),
            start_date: defaultStartDate(
              params.filters.startDate.value,
              params.filters.startDate.initialValue!
            ),
            end_date: defaultEndDate(
              params.filters.endDate.value,
              params.filters.endDate.initialValue!
            ),
          },
        }
      );

      return {
        ...response.data,
        types: response.data.data.map((data: { type: string }) => data.type),
      };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const message: string = error.response?.data.detail;
        return rejectWithValue({ showError: true, message: message });
      }
    }
  }
);
