/* authSlice.ts */

/* Redux-Toolkit */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

/* Cookies */
import Cookies from "js-cookie";

/* Thunks */
import {
  loginWithCredentials,
  qrCode,
  refreshAccessToken,
  logoutWithRefreshToken,
  loginWithGoogle,
  loginWithOTP,
} from "src/redux/thunks/auth/authThunk";

/* interface */
import { AuthProps } from "src/shared/interface/common/Auth";
import { AuthSliceProps } from "src/shared/interface/common/Slice";
import { BaseErrorProps } from "src/shared/interface/common/Error";
import { TwoFaResponse } from "src/shared/interface/api/TwoFa";
import { TokenType } from "src/shared/interface/api/Token";
import { OTPResponse } from "src/shared/interface/api/TwoFa";

const initialState: AuthSliceProps = {
  data: {
    isAuthenticated: false,
    expirationTime: null,
  },
  information: {
    credentials: {
      usernameOrEmail: "",
      password: "",
    },
    qrCode: "",
    qrCodeRequired: false,
    twoFaRequired: false,
  },
  isLoading: false,
  error: undefined,
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    clearAuthState: (state) => {
      state.data = {
        isAuthenticated: false,
        expirationTime: null,
      };
      Cookies.remove(TokenType.ACCESS_TOKEN);
      Cookies.remove(TokenType.REFRESH_TOKEN);
    },
    clearError: (state) => {
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    /**
     * loginWithCredentials
     **/
    builder.addCase(loginWithCredentials.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      loginWithCredentials.rejected,
      (state, action: PayloadAction<TwoFaResponse | undefined>) => {
        state.isLoading = false;

        state.information = {
          credentials: action.payload?.credentials!,
          qrCode: action.payload?.qrCode!,
          qrCodeRequired: action.payload?.qrCodeRequired!,
          twoFaRequired: action.payload?.twoFaRequired!,
        };

        state.error = {
          showError: action.payload!.showError,
          message: action.payload!.message,
        };
      }
    );
    builder.addCase(
      loginWithCredentials.fulfilled,
      (state, action: PayloadAction<AuthProps>) => {
        state.data = action.payload;
        state.isLoading = false;
        state.error = undefined;
      }
    );

    /**
     * loginWithOTP
     **/
    builder.addCase(
      loginWithOTP.fulfilled,
      (state, action: PayloadAction<AuthProps>) => {
        state.data = action.payload;
        state.isLoading = false;
        state.error = undefined;
      }
    );
    builder.addCase(
      loginWithOTP.rejected,
      (state, action: PayloadAction<BaseErrorProps | undefined>) => {
        state.isLoading = false;
        state.error = {
          showError: action.payload!.showError,
          message: action.payload!.message,
        };
      }
    );

    /**
     * loginWithGoogle
     */
    builder.addCase(loginWithGoogle.pending, (state) => {
      state.isLoading = false;
    });
    builder.addCase(
      loginWithGoogle.fulfilled,
      (state, action: PayloadAction<AuthProps>) => {
        state.data = action.payload;
        state.isLoading = false;
      }
    );
    builder.addCase(
      loginWithGoogle.rejected,
      (state, action: PayloadAction<BaseErrorProps | undefined>) => {
        state.isLoading = false;
        state.error = {
          showError: action.payload!.showError,
          message: action.payload!.message,
        };
      }
    );
    builder.addCase(
      qrCode.fulfilled,
      (state, action: PayloadAction<OTPResponse>) => {
        state.information = {
          ...state.information!,
          qrCode: action.payload.qr_code!,
        };
      }
    );
    /**
     * refreshAccessToken
     **/
    builder.addCase(refreshAccessToken.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      refreshAccessToken.fulfilled,
      (state, action: PayloadAction<AuthProps>) => {
        state.data = action.payload;
        state.isLoading = false;
      }
    );

    /**
     * logoutWithRefreshToken
     **/
    builder.addCase(logoutWithRefreshToken.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      logoutWithRefreshToken.fulfilled,
      (state, action: PayloadAction<AuthProps>) => {
        state.data = action.payload;
        state.information = {
          credentials: {
            usernameOrEmail: "",
            password: "",
          },
          qrCode: "",
          qrCodeRequired: false,
          twoFaRequired: false,
        };
        state.isLoading = false;
      }
    );
    builder.addCase(
      logoutWithRefreshToken.rejected,
      (state, action: PayloadAction<BaseErrorProps | undefined>) => {
        state.isLoading = false;
        state.error = {
          showError: action.payload!.showError,
          message: action.payload!.message,
        };
      }
    );
  },
});

/* Action */
export const { clearAuthState, clearError } = authSlice.actions;

/* Reducer */
export default authSlice.reducer;
