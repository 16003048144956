/* React */
import React from "react";

/* Stylesheet */
import styles from "./PageLayoutWrapper.module.scss";

/* Interface */
interface PageLayoutWrapperProps {
  children: React.ReactNode;
}

const PageLayoutWrapper: React.FC<PageLayoutWrapperProps> = ({ children }) => {
  return <div className={styles.page_layout_wrapper}>{children}</div>;
};

export default PageLayoutWrapper;
