/* React */
import React, { useState, useEffect } from "react";

/* React-Router */
import { useLocation } from "react-router";

/* Ionic */
import { IonItem, IonIcon } from "@ionic/react";

/* Font-Awesome */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/* Stylesheet */
import styles from "./NavItem.module.scss";

/* Interfaces */
import { NavItemProps } from "src/shared/interface/common/NavItem";

interface NavContainerProps {
  item: NavItemProps;
  showLines?: boolean;
  showName?: boolean;
  disabled?: boolean;
  scssProps?: ScssProps;
}

interface ScssProps {
  paddingStart?: string;
  width?: string;
  activeColor?: string;
  borderRadius?: string;
}

const NavItem: React.FC<NavContainerProps> = ({
  item,
  showLines,
  showName,
  disabled,
  scssProps,
}) => {
  const path = useLocation();

  // currentPath
  const [currentPath, setCurrentPath] = useState<string>(path.pathname);

  useEffect(() => {
    setCurrentPath(path.pathname);
  }, [path]);

  const isActive =
    currentPath === item.path || currentPath.startsWith(`${item.path}/`);

  return (
    <IonItem
      style={{
        "--padding-start": scssProps?.paddingStart,
        width: scssProps?.width,
        "--background": isActive ? scssProps?.activeColor : undefined,
        borderRadius: scssProps?.borderRadius,
      }}
      aria-label={item.name}
      className={styles.nav_item}
      lines={showLines ? "full" : "none"}
      disabled={disabled}
      routerLink={item.path}
      button
    >
      {typeof item.icon === "string" ? (
        <IonIcon className={styles.icon} icon={item.icon} slot="start" />
      ) : (
        <FontAwesomeIcon className={styles.icon} icon={item.icon} />
      )}
      {showName ? item.name : undefined}
    </IonItem>
  );
};

export default NavItem;
