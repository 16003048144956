/* legend.ts */

/**
 * @file legend.ts
 *
 * @description
 * This file contains helper functions for creating legend information.
 * There are different types of charts and data formats returned from the backend.
 *
 * @purpose Helper functions for creating legend information in a unified format.
 */

/* Utils */
import { capitalizeFirstLetter } from "src/utils/helper/string-transformation";

/* Interface */
import { LegendProps } from "src/shared/interface/charts/Legend";

export const createLegendInformation = (data: any[]): LegendProps[] => {
  return data.map((data) => {
    return {
      total: data.total,
      name: data.name ? capitalizeFirstLetter(data.name) : "Full sample",
      color: data.marker.color,
    };
  });
};
