/* React */
import React, { useEffect } from "react";

/* React-Router */
import { Route } from "react-router-dom";
import { IonApp, IonRouterOutlet, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

/* React-Redux */
import { useDispatch, useSelector } from "react-redux";
import { clearAuthState } from "src/redux/features/auth/authSlice";
import { AppDispatch, RootState } from "src/redux/store";

/* Cookies */
import Cookies from "js-cookie";

/* Google */
import { GoogleOAuthProvider } from "@react-oauth/google";

/* Thunks */
import { providerInfo } from "src/redux/thunks/auth/providerThunk";
import { refreshAccessToken } from "src/redux/thunks/auth/authThunk";

/* Utils */
import ProtectedRoute from "./utils/routes/protected-route/ProtectedRoute";

/* Pages */
import RedirectHandler from "src/components/redirect-handler/RedirectHandler";
import Welcome from "src/pages/welcome/Welcome";
import Insights from "src/pages/insights/Insights";

/* Component */
import MainMenu from "src/components/menus/main-menu/MainMenu";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variable.scss";

/* Stylesheet */
import "./theme/globals.scss";

/* Interface */
import { TokenType } from "src/shared/interface/api/Token";

setupIonicReact();

const App: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  // providerState
  const providerState = useSelector((state: RootState) => state.provider);
  const { data: providerData } = providerState;

  // authState
  const authState = useSelector((state: RootState) => state.auth);
  const { data: authData } = authState;

  useEffect(() => {
    dispatch(providerInfo());
  }, [dispatch]);

  useEffect(() => {
    const accessToken = Cookies.get(TokenType.ACCESS_TOKEN);
    const refreshToken = Cookies.get(TokenType.REFRESH_TOKEN);

    if (!accessToken || !refreshToken) {
      dispatch(clearAuthState());
    }
  }, [dispatch]);

  useEffect(() => {
    if (authData?.isAuthenticated && authData.expirationTime) {
      const currentTime = Date.now();
      const timeUntilExpiration = authData.expirationTime - currentTime;

      if (timeUntilExpiration > 0) {
        const timeout = setTimeout(() => {
          dispatch(refreshAccessToken());
        }, timeUntilExpiration);

        return () => clearTimeout(timeout);
      }
    }
  }, [dispatch, authData?.isAuthenticated, authData?.expirationTime]);

  return (
    <GoogleOAuthProvider clientId={providerData!.google.clientId}>
      <IonApp>
        <IonReactRouter>
          <MainMenu />
          <IonRouterOutlet id="main-content">
            <Route path="/redirect" component={RedirectHandler} />
            <Route exact path="/" component={Welcome} />
            <ProtectedRoute
              exact={false}
              path="/insights"
              component={Insights}
            />
          </IonRouterOutlet>
        </IonReactRouter>
      </IonApp>
    </GoogleOAuthProvider>
  );
};

export default App;
