/* React */
import React from "react";

/* Stylesheet */
import styles from "./Illustration.module.scss";

const Illustration: React.FC = () => {
  return <div className={styles.illustration}></div>;
};

export default Illustration;
