/* React */
import React, { useEffect, useRef, useState } from "react";

/* Ionic */
import { close } from "ionicons/icons";

/* Utils */
import useChartRenderer from "src/utils/hooks/useChartRenderer";

/* Components */
import PrimaryFabButton from "src/components/shared/buttons/primary-fab-button/PrimaryFabButton";

/* Stylesheet */
import styles from "./ModalWrapper.module.scss";
/* Interface */
interface ModalWrapperProps {
  title?: string;
  children: React.ReactNode;
  onClick: () => void;
}

const ModalWrapper: React.FC<ModalWrapperProps> = ({
  title,
  children,
  onClick,
}) => {
  const { isLoading } = useChartRenderer({ timeout: 250 });
  const containerRef = useRef<HTMLDivElement>(null);

  // isScrollable
  const [isScrollable, setIsScrollable] = useState(false);

  const checkScrollable = () => {
    const container = containerRef.current;
    if (!container) return;

    const isContentScrollable = container.scrollHeight > container.clientHeight; // Inhalt höher als sichtbarer Bereich
    setIsScrollable(isContentScrollable);
  };

  useEffect(() => {
    const handleResize = () => {
      checkScrollable();
    };
    checkScrollable();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={styles.modal_wrapper}>
      <div className={styles.label_container}>
        <div className={styles.title}>{title}</div>
        <PrimaryFabButton
          scssProps={{
            background: "transparent",
          }}
          ariaLabel={"Close"}
          icon={close}
          onClick={onClick}
        />
      </div>
      {!isLoading ? (
        <div
          ref={containerRef}
          style={{ display: isScrollable ? "block" : "flex" }}
          className={styles.children_container}
        >
          {children}
        </div>
      ) : undefined}
    </div>
  );
};

export default ModalWrapper;
