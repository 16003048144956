/* React */
import React, { useState } from "react";

/* Swiper */
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import "swiper/scss";

/* Utils */
import ChartWrapper from "src/utils/wrapper/chart-wrapper/ChartWrapper";
import { capitalizeFirstLetter } from "src/utils/helper/string-transformation";

/* Components */
import StatsLabel from "src/components/insights/charts/boxplot/stats-container/stats-label/StatsLabel";
import StatsSlide from "src/components/insights/charts/boxplot/stats-container/stats-slide/StatsSlide";
import SwiperControls from "src/components/shared/swiper/swiper-controls/SwiperControls";

/* Stylesheet */
import styles from "./StatsContainer.module.scss";

/* Interfaces */
import {
  ChartType,
  BaseChartProps,
} from "src/shared/interface/charts/BaseChart";

import { BoxplotProps, StatsProps } from "src/shared/interface/charts/Boxplot";

interface StatsContainerProps {
  data: BaseChartProps<BoxplotProps[]>;
}

const StatsContainer: React.FC<StatsContainerProps> = ({ data }) => {
  // activeIndex
  const [activeIndex, setActiveIndex] = useState<number>(0);

  // swiperInstance
  const [swiperInstance, setSwiperInstance] = useState<SwiperClass | null>(
    null
  );

  // statsData
  const statsData: StatsProps[] = data.data
    .filter((data: { type: string }) => data.type === ChartType.BOX)
    .map((stats: { stats: StatsProps }) => stats.stats);

  const label: string = capitalizeFirstLetter(
    data.data.filter((data: { type: string }) => data.type === ChartType.BOX)[
      activeIndex
    ].name!
  );

  const length: number = statsData.length;
  const showSwiperControls: boolean = length > 1;

  return (
    <ChartWrapper
      scssProps={{
        padding: "0px",
        chartHeight: "100%",
      }}
    >
      <StatsLabel label={label!} />
      <div
        style={{ height: showSwiperControls ? "85%" : "90%" }}
        className={styles.stats_container}
      >
        <Swiper
          onSwiper={setSwiperInstance}
          onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
        >
          {statsData.map((stats: StatsProps, index: number) => {
            return (
              <SwiperSlide key={index}>
                <StatsSlide activeIndex={activeIndex} stats={stats} />
              </SwiperSlide>
            );
          })}
        </Swiper>
        <SwiperControls
          scssProps={{
            height: "6%",
          }}
          swiperInstance={swiperInstance}
          stacked={true}
          length={length}
          activeIndex={activeIndex}
        />
      </div>
    </ChartWrapper>
  );
};

export default StatsContainer;
