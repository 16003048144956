/* BaseChart.ts */

export enum ChartType {
  BAR = "bar",
  HISTOGRAM = "histogram",
  BOX = "box",
  STATS = "stats",
  SCATTER = "scatter",
  TABLE = "table",
  SANKEY = "sankey",
}

export interface BaseChartProps<T> {
  total: number;
  types: string[] | string[][];
  data: T;
}
