/* React */
import React from "react";

/* React-Redux */
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store";

/* Components */
import FirstFactor from "src/components/login/two-factor-authentication/first-factor/FirstFactor";
import SecondFactor from "src/components/login/two-factor-authentication/second-factor/SecondFactor";

const TwoFactorAuthentication: React.FC = () => {
  // authState
  const authState = useSelector((state: RootState) => state.auth);

  const { information } = authState;

  if (!information?.twoFaRequired) {
    return <FirstFactor />;
  } else {
    return <SecondFactor />;
  }
};

export default TwoFactorAuthentication;
