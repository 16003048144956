/* React */
import React from "react";

/* Component(s) */
import Google from "src/components/login/provider-container/google/Google";

/* Stylesheet */
import styles from "./ProviderContainer.module.scss";

const ProviderContainer: React.FC = () => {
  return (
    <div className={styles.provider_container}>
      <div className={styles.info_message}>Or continue with</div>
      <Google />
    </div>
  );
};

export default ProviderContainer;
