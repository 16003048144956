/* chart-config.ts */

/**
 * @file chart-config.ts
 *
 * @description This file contains layout and configuration settings for various types of charts.
 *
 * @purpose Defines reusable layout templates for different chart types, including:
 * - Bar Chart
 * - Histogram
 * - Box Plot
 * - Scatter Plot
 * - Sankey Diagram
 */

const font = {
  size: 12,
  color: "#91a4bf",
};

const colors = {
  backgroundColor: "transparent",
  gridColor: "#C3CDDC",
};

const xAxis = {
  fixedrange: true,
  titlefont: {
    color: font.color,
  },
  tickfont: {
    size: font.size,
    color: font.color,
  },
};

const yAxis = {
  fixedrange: true,
  tickfont: {
    size: font.size,
    color: font.color,
  },
  gridcolor: colors.gridColor,
  zerolinecolor: colors.gridColor,
};

const common = {
  paper_bgcolor: colors.backgroundColor,
  plot_bgcolor: colors.backgroundColor,
  showlegend: false,
};

export const config = {
  displayModeBar: false,
  displaylogo: false,
  staticPlot: true,
};

/* Bar Chart */
export const barchartLayout = {
  ...common,
  barmode: "stack",
  margin: {
    pad: 12,
  },
  xaxis: xAxis,
  yaxis: {
    ...yAxis,
  },
};

/* Histogram */
export const histogramLayout = {
  ...common,
  barmode: "stack",
  margin: {
    pad: 12,
  },
  xaxis: {
    ...xAxis,
  },

  yaxis: {
    ...yAxis,
  },
};

/* Box Plot */
export const boxplotLayout = {
  ...common,
  margin: {
    pad: 12,
  },
  xaxis: {
    ...xAxis,
  },
  yaxis: {
    ...yAxis,
    showticklabels: false,
  },
};

/* Scatter Plot */
export const scatterPlotLayout = {
  ...common,
  margin: {
    pad: 12,
  },
  xaxis: {
    ...xAxis,
    zerolinecolor: colors.gridColor,
  },
  yaxis: {
    ...yAxis,
    showticklabels: true,
    range: [0, 1.175],
  },
};

/* Sankey Diagram */
export const sankeyDiagramLayout = {
  ...common,
  font: {
    size: font.size,
    color: "#ffffff",
  },
  xaxis: {
    ...xAxis,
  },
  yaxis: {
    ...yAxis,
  },
};

/* Sankey Boxplot */
export const sankeyBoxplotLayout = {
  ...common,
  margin: {
    pad: 12,
  },
  xaxis: {
    ...xAxis,
  },
  yaxis: {
    ...yAxis,
    title: {
      text: "Time (mth)",
      standoff: 21,
      font: {
        size: font.size,
        color: font.color,
      },
    },
  },
};
