/* React */
import React from "react";

/* Ionic */
import { IonContent } from "@ionic/react";

/* Components */
import InsightsMenu from "src/components/menus/insights/insights-menu/InsightsMenu";

/* Stylesheet */
import styles from "./InsightsPageWrapper.module.scss";

/* Interface */
interface InsightsPageWrapperProps {
  children: React.ReactNode;
}

const InsightsPageWrapper: React.FC<InsightsPageWrapperProps> = ({
  children,
}) => {
  return (
    <div className={styles.insights_page_wrapper}>
      <div className={styles.insights_menu_container}>
        <InsightsMenu />
      </div>
      <IonContent className={styles.content}>{children}</IonContent>
    </div>
  );
};

export default InsightsPageWrapper;
