/* React */
import React, { useEffect } from "react";

/* React-Redux */
import { useDispatch, useSelector } from "react-redux";

import {
  setDisabled,
  clear,
} from "src/redux/features/insights/insights-filter/insightsFilterSlice";

import { AppDispatch, RootState } from "src/redux/store";

/* Thunk */
import { fetchTreatmentPatterns } from "src/redux/thunks/treatment-patterns/treatmentPatternsThunk";

/* Utils */
import useChartLoadingScreen from "src/utils/hooks/useChartLoadingScreen";
import ChartLayoutWrapper from "src/utils/wrapper/chart-layout-wrapper/ChartLayoutWrapper";
import { complexLayout } from "src/utils/layout/complexLayout";

/* Component */
import ToastMessage from "src/components/shared/toast-message/ToastMessage";

/* Interfaces */
import { FilterType } from "src/shared/interface/common/InsightsFilter";
import { LayoutProps } from "src/shared/interface/common/Layout";
import { ErrorType } from "src/shared/interface/common/Error";

const TreatmentPatterns: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  // mainConditionState
  const mainConditionState = useSelector(
    (state: RootState) => state.mainCondition
  );

  // insightsFilterState
  const insightsFilterState = useSelector(
    (state: RootState) => state.insightsFilter
  );

  // treatmentPatternsState
  const treatmentPatternsState = useSelector(
    (state: RootState) => state.treatmentPatterns
  );

  // sankeyDiagramOptionsState
  const sankeyDiagramOptionsState = useSelector(
    (state: RootState) => state.sankeyDiagramOptions
  );

  const { data, isFetching, isUpdating, error } = treatmentPatternsState;

  useChartLoadingScreen(isFetching!, isUpdating!);

  useEffect(() => {
    dispatch(setDisabled({ key: FilterType.FEATURES, value: true }));

    return () => {
      dispatch(clear(FilterType.FEATURES));
    };
  }, [dispatch]);

  useEffect(() => {
    if (mainConditionState.value) {
      dispatch(
        fetchTreatmentPatterns({
          mainCondition: mainConditionState.value,
          filters: {
            cohort: insightsFilterState.cohorts.value!,
            startDate: insightsFilterState.firstYear,
            endDate: insightsFilterState.lastYear,
            maxDepth: sankeyDiagramOptionsState.maxDepth,
            minWidth: sankeyDiagramOptionsState.minWidth,
          },
        })
      );
    }
  }, [
    dispatch,
    mainConditionState.value,
    insightsFilterState,
    sankeyDiagramOptionsState.maxDepth,
    sankeyDiagramOptionsState.minWidth,
  ]);

  const types = data?.types as string[][];

  const layout: LayoutProps = complexLayout(types, data!);

  return (
    <React.Fragment>
      <ChartLayoutWrapper layout={layout} />
      {error ? (
        <ToastMessage
          isOpen={error.showError}
          message={error.message!}
          level={ErrorType.ERROR}
        />
      ) : undefined}
    </React.Fragment>
  );
};

export default TreatmentPatterns;
