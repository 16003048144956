/* useKeyToggle.ts */

/* React */
import { useEffect } from "react";

/* Ionic */
import { menuController } from "@ionic/core/components";

/* Interface */
interface UseKeyToggleProps {
  key: string;
  menuId: string;
  isDisabled: boolean;
}

const useKeyToggle = ({ key, menuId, isDisabled }: UseKeyToggleProps) => {
  useEffect(() => {
    const keyDownHandler = (e: KeyboardEvent) => {
      if (e.key === key) {
        menuController.toggle(menuId).catch((error: Error) => {
          console.error(`Error toggling ${menuId}`, error.message);
        });
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [isDisabled, key, menuId]);
};

export default useKeyToggle;
