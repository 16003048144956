/* React */
import React from "react";

/* React-Redux */
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import {
  setMaxDepth,
  setMinWidth,
} from "src/redux/features/insights-options/sankey-diagram/sankeyDiagramOptionsSlice";

/* Component(s) */
import RangeSlider from "src/components/shared/range-slider/RangeSlider";

/* Stylesheet */
import styles from "./SankeyDiagramOptions.module.scss";

const SankeyDiagramOptions: React.FC = () => {
  const dispatch = useDispatch();

  // sankeyDiagramOptionsState
  const sankeyDiagramOptionsState = useSelector(
    (state: RootState) => state.sankeyDiagramOptions
  );

  const { maxDepth, minWidth } = sankeyDiagramOptionsState;

  const handleNumberTreatmentLines = (value: number) => {
    dispatch(setMaxDepth(value));
  };

  const handleTreatmentZoom = (value: number) => {
    dispatch(setMinWidth(value));
  };

  return (
    <div className={styles.sankey_diagram_options}>
      <RangeSlider
        label={"Number of treatment lines"}
        min={1}
        max={10}
        ticks={true}
        snaps={true}
        pin={true}
        value={maxDepth}
        onChange={handleNumberTreatmentLines}
      />
      <RangeSlider
        label={"Treatment zoom"}
        min={1}
        max={20}
        ticks={true}
        snaps={true}
        pin={true}
        value={minWidth}
        onChange={handleTreatmentZoom}
      />
    </div>
  );
};

export default SankeyDiagramOptions;
