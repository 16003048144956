/* React */
import React from "react";

/* React-Redux */
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import { clearError } from "src/redux/features/auth/authSlice";

/* Util(s) */
import LoginPageWrapper from "src/utils/wrapper/login-page-wrapper/LoginPageWrapper";

/* Component(s) */
import ToastMessage from "src/components/shared/toast-message/ToastMessage";

/* Interface(s) */
import { ErrorType } from "src/shared/interface/common/Error";
import TwoFactorAuthentication from "src/components/login/two-factor-authentication/TwoFactorAuthentication";

const Login: React.FC = () => {
  // authState
  const authState = useSelector((state: RootState) => state.auth);

  const { error } = authState;

  return (
    <LoginPageWrapper>
      <TwoFactorAuthentication />
      {/* <SecondFactor /> */}
      {error?.showError ? (
        <ToastMessage
          isOpen={error.showError}
          message={error.message!}
          level={ErrorType.ERROR}
          clearError={clearError}
        />
      ) : undefined}
    </LoginPageWrapper>
  );
};

export default Login;
