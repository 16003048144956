/* React */
import React, { useRef } from "react";

/* Plotly */
import Plot from "react-plotly.js";

/* Utils */
import { config, sankeyBoxplotLayout } from "src/utils/config/chart-config";

/* Component */
import ChartWrapper from "src/utils/wrapper/chart-wrapper/ChartWrapper";

/* Stylesheet */
import styles from "./Boxplot.module.scss";

/* Interfaces */
import { SankeyBoxplotProps } from "src/shared/interface/charts/SankeyDiagram";
import { ChartLabelProps } from "src/shared/interface/common/ChartLabel";
import { createLegendInformation } from "src/utils/helper/legend";

const Boxplot: React.FC<{
  data: SankeyBoxplotProps;
}> = ({ data }) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const boxplot = (
    <div ref={ref}>
      <Plot
        className={styles.boxplot}
        data={data.data}
        config={config}
        layout={sankeyBoxplotLayout}
        useResizeHandler={true}
      />
    </div>
  );

  const chartLabel: ChartLabelProps = {
    label: {
      name: data.name,
    },
    chart: boxplot,
    legend: createLegendInformation(data.data),
  };

  return (
    <ChartWrapper
      scssProps={{ height: "auto", boxShadow: "none" }}
      label={chartLabel}
    >
      {boxplot}
    </ChartWrapper>
  );
};

export default Boxplot;
