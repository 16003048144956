/* React */
import React from "react";

/* React-Redux */
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store";

/* Component(s) */
import PrimaryButton from "src/components/shared/buttons/primary-button/PrimaryButton";

const Google: React.FC = () => {
  // providerState
  const providerState = useSelector((state: RootState) => state.provider);

  const { data } = providerState;

  const loginWithRedirect = () => {
    const clientId = data?.google.clientId;
    const scope = encodeURIComponent("profile email");
    const responseType = "token";

    const url =
      `https://accounts.google.com/o/oauth2/v2/auth?` +
      `client_id=${clientId}&` +
      `redirect_uri=${data?.google.redirectUri}&` +
      `response_type=${responseType}&` +
      `scope=${scope}`;

    window.location.href = url;
  };

  return (
    <PrimaryButton
      scssProps={{
        color: "var(--ion-color-text-primary)",
        backgroundColor: "var(--ion-background-chart)",
      }}
      type={"button"}
      ariaLabel={"Google"}
      icon={"/assets/icons/google.svg"}
      label={"Google"}
      onClick={loginWithRedirect}
    />
  );
};

export default Google;
