/* React */
import React from "react";

/* Components */
import Barchart from "src/components/insights/charts/barchart/Barchart";
import Histogram from "src/components/insights/charts/histogram/Histogram";
import Boxplot from "src/components/insights/charts/boxplot/Boxplot";
import StatsContainer from "src/components/insights/charts/boxplot/stats-container/StatsContainer";
import SurvivalPlot from "src/components/insights/charts/survival-plot/SurvivalPlot";
import Table from "src/components/shared/table/Table";

/* Interfaces */
import {
  ChartType,
  BaseChartProps,
} from "src/shared/interface/charts/BaseChart";

import { BarchartProps } from "src/shared/interface/charts/Barchart";
import { HistogramProps } from "src/shared/interface/charts/Histogram";
import { BoxplotProps } from "src/shared/interface/charts/Boxplot";
import {
  SurvivalPlotProps,
  EventTableProps,
} from "src/shared/interface/charts/SurvivalPlot";
import { ComponentProps } from "src/shared/interface/common/Layout";
import { TableProps } from "src/shared/interface/charts/Table";

const createComponents = (
  type: string,
  index: number,
  stacked: boolean,
  data: BaseChartProps<
    | BarchartProps[]
    | HistogramProps[]
    | BoxplotProps[]
    | SurvivalPlotProps[]
    | EventTableProps[]
  >
): ComponentProps[] => {
  const tableData: TableProps<EventTableProps[]>[] = (data.data as any)
    .filter((data: any) => data.type === ChartType.TABLE)
    .map((data: any) => ({
      ...data,
      eventTable: data.eventTable.map((e: any) => ({
        ...e,
        timeline: Math.round(e.timeline / 33.44),
      })),
    }));
  switch (type) {
    case ChartType.BAR:
      return [
        {
          component: (
            <Barchart
              key={`barchart-${index}`}
              index={index}
              stacked={stacked}
              data={data as BaseChartProps<BarchartProps[]>}
            />
          ),
          fullWidth: true,
        },
      ];
    case ChartType.HISTOGRAM:
      return [
        {
          component: (
            <Histogram
              key={`histogram-${index}`}
              index={index}
              stacked={stacked}
              data={data as BaseChartProps<HistogramProps[]>}
            />
          ),
          fullWidth: true,
        },
      ];
    case ChartType.BOX:
      return [
        {
          component: (
            <Boxplot
              key={`boxplot-${index}`}
              index={index}
              stacked={stacked}
              data={data as BaseChartProps<BoxplotProps[]>}
            />
          ),
          size: "8",
        },
        {
          component: (
            <StatsContainer data={data as BaseChartProps<BoxplotProps[]>} />
          ),
          size: "4",
        },
      ];
    case ChartType.SCATTER:
      return [
        {
          component: (
            <SurvivalPlot
              key={`survival-${index}`}
              index={index}
              stacked={stacked}
              data={data as BaseChartProps<SurvivalPlotProps[]>}
            />
          ),
          fullWidth: true,
        },
      ];
    case ChartType.TABLE:
      return [
        {
          component: (
            <Table
              key={`table-${index}`}
              index={index}
              stacked={stacked}
              dataKey={"eventTable"}
              data={tableData}
            />
          ),
          fullWidth: true,
        },
      ];
    default:
      return [];
  }
};

/**
 * getIndexRange() is used required to compute the range of index for a layout that is not stacked
 * 'not stacked' means that each cohort has its own row and the data is split up
 **/

const getIndexRange = (
  index: number,
  types: string[],
  data: BaseChartProps<
    | BarchartProps[]
    | HistogramProps[]
    | BoxplotProps[]
    | SurvivalPlotProps[]
    | EventTableProps[]
  >
) => {
  const totalEntries = data.data.length;
  const uniqueTypeSize = new Set(types).size;
  const modulo = totalEntries / uniqueTypeSize;
  return index % modulo;
};

const createRows = (types: string[]) => {
  return types.map((type) => {
    return {
      row: type,
    };
  });
};

const createCols = (
  rows: { row: string }[],
  stacked: boolean,
  types: string[],
  data: BaseChartProps<
    | BarchartProps[]
    | HistogramProps[]
    | BoxplotProps[]
    | SurvivalPlotProps[]
    | EventTableProps[]
  >
) => {
  return rows.map((row, index) => {
    return {
      cols: createComponents(
        row.row,
        stacked ? index : getIndexRange(index, types, data),
        stacked,
        data
      ),
    };
  });
};

export const basicLayout = (
  types: string[],
  stacked: boolean,
  data: BaseChartProps<
    | BarchartProps[]
    | HistogramProps[]
    | BoxplotProps[]
    | SurvivalPlotProps[]
    | EventTableProps[]
  >
) => {
  const tmpTypes = stacked ? [...new Set(types)] : [...types].sort();
  const rows = createRows(tmpTypes);
  const cols = createCols(rows, stacked, tmpTypes, data);
  return { rows: cols };
};
