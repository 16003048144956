/* React */
import React from "react";

/* Stylesheet */
import styles from "./QRCodeContainer.module.scss";

/* Interface */
interface QRCodeProps {
  code: string | undefined;
}

const QRCode: React.FC<QRCodeProps> = ({ code }) => {
  return (
    <div className={styles.qr_code_container}>
      <div className={styles.info_message}>
        <h1>Two-Factor Authentication</h1>
        <p>
          Scan the QR-Code with your <span>authenticator app</span> and enter
          the code to enable two-factor authentication.
        </p>
      </div>
      <img className={styles.qr_code} src={code} alt="QR-Code" />
    </div>
  );
};

export default QRCode;
