/* treatmentPatternsThunk.ts */

/* Redux-Toolkit */
import { createAsyncThunk } from "@reduxjs/toolkit";

/* Axios */
import axios from "axios";

/* Utils */
import axiosInterceptor from "src/utils/helper/axios-interceptor";

import {
  defaultCohort,
  defaultStartDate,
  defaultEndDate,
} from "src/utils/helper/insights-filter";

/* interface */
import { BaseChartProps } from "src/shared/interface/charts/BaseChart";
import { SankeyDiagramProps } from "src/shared/interface/charts/SankeyDiagram";
import { BaseChartDataRequest } from "src/shared/interface/api/ChartData";
import { TreatmentFilterRequest } from "src/shared/interface/api/Filters";
import { BaseErrorProps } from "src/shared/interface/common/Error";

interface DataItem {
  sankey: { type: string };
  response_table: { type: string };
  boxplot: { type: string }[];
}

export const fetchTreatmentPatterns = createAsyncThunk<
  BaseChartProps<SankeyDiagramProps[]>,
  BaseChartDataRequest<TreatmentFilterRequest>,
  { rejectValue: BaseErrorProps }
>(
  "treatmentPatterns/fetchTreatmentPatterns",
  async (
    params: BaseChartDataRequest<TreatmentFilterRequest>,
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInterceptor.get("/data/treatment_patterns", {
        params: {
          main_condition: params.mainCondition,
          cohort: defaultCohort(params.filters.cohort),
          start_date: defaultStartDate(
            params.filters.startDate.value,
            params.filters.startDate.initialValue!
          ),
          end_date: defaultEndDate(
            params.filters.endDate.value,
            params.filters.endDate.initialValue!
          ),
          max_depth: params.filters.maxDepth,
          min_width: params.filters.minWidth,
        },
      });

      return {
        ...response.data,
        types: response.data.data.map(
          ({ sankey, response_table, boxplot }: DataItem) => [
            sankey.type,
            response_table.type,
            ...boxplot.map(({ type }) => type),
          ]
        ),
      };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const message: string = error.response?.data.detail;
        return rejectWithValue({ showError: true, message: message });
      }
    }
  }
);
