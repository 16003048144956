/* React */
import React from "react";

/* Component */
import NavItem from "src/components/shared/nav-item/NavItem";

/* Constants */
import { INSIGHTS_MENU_NAV_ITEMS } from "src/shared/constants/NavItems";

/* Stylesheet */
import styles from "./InsightsMenu.module.scss";

/* Interface */
import { NavItemProps } from "src/shared/interface/common/NavItem";

const InsightsMenu: React.FC = () => {
  const navItems = INSIGHTS_MENU_NAV_ITEMS;

  return (
    <div className={styles.insights_menu}>
      <div className={styles.block_container}>
        {navItems.map((item: NavItemProps, index: number) => {
          return (
            <NavItem
              key={index}
              item={item}
              showLines={false}
              showName={false}
              scssProps={{
                width: "62px",
                activeColor: "var(--ion-color-active-item)",
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default InsightsMenu;
