/* React */
import React from "react";

/* Ionic */
import { IonCard } from "@ionic/react";

/* Stylesheet */
import styles from "./Disclaimer.module.scss";
import Logo from "../logo/Logo";

const Disclaimer: React.FC = () => {
  return (
    <IonCard className={styles.disclaimer}>
      <Logo
        scssProps={{
          width: "100%",
        }}
      />
      <p className={styles.paragraph}>
        Please note: <span>syndena Insights</span> is currently in development,
        undergoing continous updates and reviews. The data provided may be
        preliminary, incomplete, or subjetct to errors. We urge users to
        exercise caution and discerment when interpreting the displayed
        information. While we strive for accuracy, we cannot guranteee
        completeness, reliabity, or suitablity of the data, graphics, or
        visualizations. Your use of this dashboard is at your discretion and
        risk. We warmly welcome feedback under{" "}
        <span>
          <a href="mailto:masteryoda@starwars.com">support@syndena.com</a>
        </span>{" "}
        and suggestions for enhancement as we endeavor to refine and improve
        functionality and precision of this tool.
      </p>
    </IonCard>
  );
};

export default Disclaimer;
