/* React */
import React from "react";

/* Ionic */
import { IonGrid, IonRow, IonCol } from "@ionic/react";

/* Stylesheet */
import styles from "./Cohorts.module.scss";

/* Interface */
import { LegendProps } from "src/shared/interface/charts/Legend";

const Cohorts: React.FC<{ legend: LegendProps[] }> = ({ legend }) => {
  const columns = [];

  for (let i = 0; i < legend?.length; i += 2) {
    columns.push(legend.slice(i, i + 2));
  }

  return (
    <IonGrid className={styles.cohorts}>
      <IonRow className={styles.row}>
        {columns.map((column: LegendProps[], index: number) => (
          <IonCol key={index} size="6">
            {column.map((item: LegendProps, index: number) => (
              <div className={styles.cohort} key={index}>
                <div
                  className={styles.wrapper_container}
                  style={{ display: "flex" }}
                >
                  <div
                    style={{ background: item.color }}
                    className={styles.color}
                  />
                  <div className={styles.name}>{item.name}</div>
                </div>
                {item.total ? (
                  <div className={styles.total}>({item.total})</div>
                ) : undefined}
              </div>
            ))}
          </IonCol>
        ))}
      </IonRow>
    </IonGrid>
  );
};

export default Cohorts;
