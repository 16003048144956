/* React */
import React from "react";

/* Stylesheet */
import styles from "./FilterLabel.module.scss";

/* Interfaces */
interface FilterLabelProps {
  scssProps?: ScssProps;
  label: string;
}

interface ScssProps {
  margin?: string;
}

const FilterLabel: React.FC<FilterLabelProps> = ({ scssProps, label }) => {
  return (
    <div
      style={{
        margin: scssProps?.margin,
      }}
      className={styles.filter_label}
    >
      <div className={styles.label}>{label}</div>
    </div>
  );
};

export default FilterLabel;
