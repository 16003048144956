/* providerThunk.ts */

/* Redux-Toolkit */
import { createAsyncThunk } from "@reduxjs/toolkit";

/* Axios */
import axiosInterceptor from "src/utils/helper/axios-interceptor";

/* interface */
import { BaseErrorProps } from "src/shared/interface/common/Error";
import { ProviderProps } from "src/shared/interface/common/Provider";

export const providerInfo = createAsyncThunk<
  ProviderProps,
  void,
  { rejectValue: BaseErrorProps }
>("auth/providerInfo", async (_, { rejectWithValue }) => {
  try {
    const response = await axiosInterceptor.get("/provider_info");

    return {
      google: {
        clientId: response.data.client_id,
        redirectUri: response.data.redirect_uri,
      },
    };
  } catch (error) {
    return rejectWithValue({
      showError: true,
      message: "An unexpected error occurred",
    });
  }
});
