/* mainConditionThunk.ts */

/* Redux-Toolkit */
import { createAsyncThunk } from "@reduxjs/toolkit";

/* Axios */
import axiosInstance from "src/utils/helper/axios-interceptor";

export const fetchMainConditions = createAsyncThunk(
  "mainCondition/fetchMainConditions",
  async () => {
    const response = await axiosInstance.get("/options/main_conditions");

    return {
      items: response.data,
    };
  }
);
