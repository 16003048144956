/* React */
import React from "react";

/* Ionic */
import { IonPage, IonContent, IonGrid, IonRow, IonCol } from "@ionic/react";

/* Components */
import Illustration from "src/components/login/illustration/Illustration";
import Controls from "src/components/login/controls/Controls";

/* Stylesheet */
import styles from "./LoginPageWrapper.module.scss";

/* Interface */
interface LoginPageWrapperProps {
  children: React.ReactNode;
}

const LoginPageWrapper: React.FC<LoginPageWrapperProps> = ({ children }) => {
  return (
    <IonPage>
      <IonContent>
        <IonGrid className={styles.grid_container}>
          <IonRow className={styles.row}>
            <IonCol
              className={`${styles.col} ${styles.illustration_col} ion-hide-lg-down`}
            >
              <div className={styles.wrapper_container}>
                <Illustration />
              </div>
            </IonCol>
            <IonCol className={`${styles.col}`}>
              <div className={styles.credentials_container}>
                <Controls />
                <div className={styles.block_container}>{children}</div>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default LoginPageWrapper;
