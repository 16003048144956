/* React */
import React from "react";

/* React-Router */
import { Route, RouteProps } from "react-router-dom";

/* React-Redux */
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store";

/* Page(s) */
import Login from "src/pages/login/Login";

/* Interface(s) */
interface ProtectedRouteProps {
  component: React.ComponentType<RouteProps>;
  path: string;
  exact?: boolean;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
  exact,
  ...rest
}) => {
  // authState
  const authState = useSelector((state: RootState) => state.auth);

  const { data } = authState;

  return (
    <Route
      {...rest}
      exact={exact}
      render={(props) => {
        return data?.isAuthenticated ? <Component {...props} /> : <Login />;
      }}
    />
  );
};

export default ProtectedRoute;
