/* React */
import React from "react";

/* React-Redux */
import { useDispatch } from "react-redux";
import { setMainCondition } from "src/redux/features/insights/mainConditionSlice";

/* Component */
import SimpleSelectContainer from "src/components/shared/simple-select-container/SimpleSelectContainer";

/* Interface */
import { FilterProps } from "src/shared/interface/common/InsightsFilter";

interface MainConditionProps {
  item: FilterProps<string | undefined>;
}

const MainCondition: React.FC<MainConditionProps> = ({ item }) => {
  const dispatch = useDispatch();

  const handleMainCondition = (value: string) => {
    dispatch(setMainCondition(value));
  };

  return (
    <React.Fragment>
      <SimpleSelectContainer
        label={"Main Condition"}
        item={item}
        handleItem={handleMainCondition}
      />
    </React.Fragment>
  );
};

export default MainCondition;
