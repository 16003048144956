/* React */
import { useEffect } from "react";

/* React-Router */
import { useHistory } from "react-router-dom";

/* React-Redux */
import { useDispatch } from "react-redux";
import { AppDispatch } from "src/redux/store";

/* Thunk(s) */
import { loginWithGoogle } from "src/redux/thunks/auth/authThunk";

const RedirectHandler = () => {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();

  useEffect(() => {
    const hash = window.location.hash;
    const params = new URLSearchParams(hash.substring(1));
    const accessToken = params.get("access_token");
    const error = params.get("error");

    if (error) {
      history.replace("/welcome");
    } else if (accessToken) {
      dispatch(loginWithGoogle(accessToken));
      history.replace("/insights");
    }
  }, [dispatch, history]);

  return null;
};

export default RedirectHandler;
