/* insightsOptionsSlice.ts */

/* Redux-Toolkit */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ChartType } from "src/shared/interface/charts/BaseChart";

/* Interface */
import { InsightsFilterOptionsProps } from "src/shared/interface/options/InsightsFilterOptions";

const initialState: InsightsFilterOptionsProps = {
  options: undefined,
};

export const insightsFilterOptions = createSlice({
  name: "insightsOptions",
  initialState: initialState,
  reducers: {
    setOptions: (state, action: PayloadAction<ChartType | undefined>) => {
      state.options = action.payload;
    },
    clearOptions: (state) => {
      state.options = undefined;
    },
  },
});

/* Actions */
export const { setOptions, clearOptions } = insightsFilterOptions.actions;

/* Reducers */
export default insightsFilterOptions.reducer;
