/* React */
import React from "react";
import { createRoot } from "react-dom/client";

/* React-Redux */
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "src/redux/store";

import App from "./App";

const Root = () => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </PersistGate>
    </Provider>
  );
};

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<Root />);
