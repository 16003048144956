/* React */
import React from "react";

/* Ionic */
import { expand } from "ionicons/icons";

/* Component */
import PrimaryFabButton from "src/components/shared/buttons/primary-fab-button/PrimaryFabButton";

/* Stylesheet */
import styles from "./Options.module.scss";

/* Interface */
interface OptionsProps {
  handleModal: (value: boolean) => void;
  component: any;
}

const Options: React.FC<OptionsProps> = ({ handleModal, component }) => {
  return (
    <React.Fragment>
      {component ? (
        <div className={styles.options}>
          <PrimaryFabButton
            scssProps={{
              background: "var(--ion-color-text-primary)",
            }}
            ariaLabel={"Expand"}
            icon={expand}
            onClick={() => {
              handleModal(true);
            }}
          />
        </div>
      ) : undefined}
    </React.Fragment>
  );
};

export default Options;
