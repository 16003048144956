/* loadingScreenSlice.ts */

/* Redux-Toolkit */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

/* Interface */
interface LoadingScreenProps {
  isFetching: boolean | undefined;
  isUpdating: boolean | undefined;
  showContent: boolean | undefined;
}

const initialState: LoadingScreenProps = {
  isFetching: undefined,
  isUpdating: undefined,
  showContent: undefined,
};

export const loadingScreenSlice = createSlice({
  name: "loadingScreen",
  initialState: initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isFetching = action.payload;
    },
    setRerender: (state, action: PayloadAction<boolean>) => {
      state.isUpdating = action.payload;
    },
    setShowContent: (state, action: PayloadAction<boolean>) => {
      state.showContent = action.payload;
    },
  },
});

/* Actions */
export const { setIsLoading, setRerender, setShowContent } =
  loadingScreenSlice.actions;

/* Reducer */
export default loadingScreenSlice.reducer;
