/* insightsFilterSlice.ts */

/* Redux-Toolkit */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

/* Thunks */
import {
  fetchInsightsFilterCategorical,
  fetchInsightsFilterNumeric,
} from "src/redux/thunks/insights-filter/insightsFilterThunks";

/* Interfaces */
import {
  FilterResponseCategorical,
  FilterResponseNumeric,
} from "src/shared/interface/api/Filters";

import {
  InsightsFilterProps,
  FilterComponentType,
} from "src/shared/interface/common/InsightsFilter";

const initialState: InsightsFilterProps = {
  features: {
    value: undefined,
    initialValue: undefined,
    items: [],
    disabled: false,
    componentType: FilterComponentType.DROPDOWN,
  },
  cohorts: {
    value: undefined,
    initialValue: undefined,
    items: [],
    disabled: false,
    componentType: FilterComponentType.DROPDOWN,
  },
  firstYear: {
    value: undefined,
    initialValue: "2013",
  },
  lastYear: {
    value: undefined,
    initialValue: "2024",
  },
};

export const insightsFilterSlice = createSlice({
  name: "insightsFilter",
  initialState: initialState,
  reducers: {
    setInsightsFilterValue: (
      state: InsightsFilterProps,
      action: PayloadAction<{ key: string; value: string }>
    ) => {
      const { key, value } = action.payload;
      const typedKey = key as keyof InsightsFilterProps;
      state[typedKey].value = value;
    },
    setDisabled: (
      state,
      action: PayloadAction<{ key: string; value: boolean }>
    ) => {
      const { key, value } = action.payload;
      const typedKey = key as keyof InsightsFilterProps;
      state[typedKey].disabled = value;
    },
    clear: (state, action: PayloadAction<string>) => {
      const typedKey = action.payload as keyof InsightsFilterProps;
      state[typedKey].disabled = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchInsightsFilterCategorical.pending, (state) => {
      // TODO
    });
    builder.addCase(fetchInsightsFilterCategorical.rejected, (state) => {
      // TODO
    });
    builder.addCase(
      fetchInsightsFilterCategorical.fulfilled,
      (state, action: PayloadAction<FilterResponseCategorical>) => {
        (
          Object.keys(action.payload) as Array<keyof FilterResponseCategorical>
        ).map((key) => {
          const filter = state[key];
          const items = action.payload[key];

          filter.items = items.map((item: string) =>
            item === "" ? "Full sample" : item
          );

          // if value is undefined and items is not empty, first value of items is set as value
          if (!filter.value && filter.items.length > 0) {
            filter.value = filter.items[0];
            filter.initialValue = filter.items[0];
          }
        });
      }
    );
    builder.addCase(
      fetchInsightsFilterNumeric.fulfilled,
      (state, action: PayloadAction<FilterResponseNumeric>) => {
        state.firstYear.value = action.payload.firstYear;
        state.firstYear.initialValue = action.payload.firstYear;
        state.lastYear.initialValue = action.payload.lastYear;
        state.lastYear.value = action.payload.lastYear;
      }
    );
  },
});

/* Actions */
export const { setInsightsFilterValue, setDisabled, clear } =
  insightsFilterSlice.actions;

/* Reducer */
export default insightsFilterSlice.reducer;
