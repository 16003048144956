/* React */
import React from "react";

/* Stylesheet */
import styles from "./Logo.module.scss";

/* Interface */
interface LogoProps {
  scssProps?: ScssProps;
}

interface ScssProps {
  margin?: string;
  display?: "block" | "flex";
  height?: string;
  width?: string;
  justifyContent?: "center" | "flex-start" | "flex-end";
}

const Logo: React.FC<LogoProps> = ({ scssProps }) => {
  return (
    <div
      style={{
        margin: scssProps?.margin,
        display: scssProps?.display,
        height: scssProps?.height,
        width: scssProps?.width,
        justifyContent: scssProps?.justifyContent,
      }}
      className={styles.logo_container}
    >
      <img
        className={styles.logo}
        src="/assets/img/logo.png"
        alt="logo"
        loading="lazy"
      />
    </div>
  );
};

export default Logo;
