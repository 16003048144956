/* useChartLoadingScreen.ts */
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  setIsLoading,
  setRerender,
} from "src/redux/features/loading-screen/loadingScreenSlice";

/* Interfaces */
import { BarchartProps } from "src/shared/interface/charts/Barchart";
import { BaseChartProps } from "src/shared/interface/charts/BaseChart";
import { BoxplotProps } from "src/shared/interface/charts/Boxplot";
import { HistogramProps } from "src/shared/interface/charts/Histogram";
import { SankeyDiagramProps } from "src/shared/interface/charts/SankeyDiagram";
import {
  SurvivalPlotProps,
  EventTableProps,
} from "src/shared/interface/charts/SurvivalPlot";

const useChartLoadingScreen = (isFetching: boolean, isUpdating: boolean) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setIsLoading(isFetching!));
  }, [dispatch, isFetching]);

  useEffect(() => {
    dispatch(setRerender(isUpdating));
  }, [dispatch, isUpdating]);
};

export default useChartLoadingScreen;
