/* React */
import React, { useRef } from "react";

/* Plotly */
import Plot from "react-plotly.js";

/* Utils */
import { config, boxplotLayout } from "src/utils/config/chart-config";
import { createBoxplotLayout } from "src/utils/helper/chart-helper";
import { createLegendInformation } from "src/utils/helper/legend";

/* Component */
import ChartWrapper from "src/utils/wrapper/chart-wrapper/ChartWrapper";

/* Stylesheet */
import styles from "./Boxplot.module.scss";

/* Interfaces */
import {
  ChartType,
  BaseChartProps,
} from "src/shared/interface/charts/BaseChart";

import { BoxplotProps } from "src/shared/interface/charts/Boxplot";
import { ChartLabelProps } from "src/shared/interface/common/ChartLabel";

const BoxPlot: React.FC<{
  index: number;
  stacked: boolean;
  data: BaseChartProps<BoxplotProps[]>;
}> = ({ index, stacked, data }) => {
  const ref = useRef<HTMLDivElement | null>(null);

  // boxplotData
  const boxplotData = data.data
    .filter((data: BoxplotProps) => data.type === ChartType.BOX)
    .map((data: BoxplotProps, index: number) => {
      const { y, ...rest } = data;
      return {
        ...rest,
        name: data.name ? data.name : "Full sample",
        // to show boxplot horizontally instead of vertically, values of x and y are swapped
        x: y,
        ...createBoxplotLayout(index),
      };
    });

  const boxplot = (
    <div ref={ref}>
      <Plot
        className={styles.boxplot}
        data={stacked ? boxplotData : [boxplotData[index]]}
        config={config}
        layout={boxplotLayout}
        useResizeHandler={true}
      />
    </div>
  );

  const chartLabel: ChartLabelProps = {
    chart: boxplot,
    legend: createLegendInformation(boxplotData),
  };

  return <ChartWrapper label={chartLabel}>{boxplot}</ChartWrapper>;
};

export default BoxPlot;
