/* React */
import React from "react";

/* Ionic */
import { IonItem, IonIcon } from "@ionic/react";
import { ban } from "ionicons/icons";

/* React-Router */
import { useHistory } from "react-router";

/* React-Redux */
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "src/redux/store";

/* Thunk(s) */
import { logoutWithRefreshToken } from "src/redux/thunks/auth/authThunk";

/* Stylesheet */
import styles from "./Logout.module.scss";

const Logout: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>();
  const authState = useSelector((state: RootState) => state.auth);

  const { data } = authState;

  return (
    <IonItem
      className={styles.logout}
      lines="full"
      disabled={!data?.isAuthenticated}
      button
      onClick={() => {
        dispatch(logoutWithRefreshToken());
        history.replace("/");
      }}
    >
      <IonIcon className={styles.icon} icon={ban} slot="start" />
      Logout
    </IonItem>
  );
};

export default Logout;
