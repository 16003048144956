/* React */
import React from "react";

/* Ionic */
import { IonContent, IonPage } from "@ionic/react";

/* Component */
import AppToolbar from "src/components/app-toolbar/AppToolbar";

/* Interface */
interface PageWrapperProps {
  children: React.ReactNode;
}

const PageWrapper: React.FC<PageWrapperProps> = ({ children }) => {
  return (
    <IonPage data-testid="page-wrapper">
      <AppToolbar />
      {/* IonContent is required, otherwise AppToolbar will not be visible */}
      <IonContent>{children}</IonContent>
    </IonPage>
  );
};

export default PageWrapper;
