/* React */
import React, { useState } from "react";

/* Ionic */
import { filter, close } from "ionicons/icons";

/* Components */
import PrimaryFabButton from "src/components/shared/buttons/primary-fab-button/PrimaryFabButton";
import QueryList from "src/components/insights/query-list-container/query-list/QueryList";

/* Stylesheet */
import styles from "./MobileContainer.module.scss";

const MobileContainer: React.FC = () => {
  // showFilter
  const [showFilter, setShowFilter] = useState<boolean>(false);

  const handleShowFilter = () => {
    setShowFilter((prev: boolean) => !prev);
  };

  return (
    <div className={styles.mobile_container}>
      <PrimaryFabButton
        scssProps={{
          background: "var(--ion-color-dark-primary)",
        }}
        ariaLabel={"Filter"}
        icon={showFilter ? close : filter}
        onClick={handleShowFilter}
      />
      {showFilter ? (
        <div className={styles.filter_container}>
          <div className={styles.query_list_container}>
            <QueryList
              scssProps={{
                color: "var(--ion-color-text-secondary)",
                backgroundColor: "var(--ion-color-dark-primary-lighter)",
              }}
            />
          </div>
        </div>
      ) : undefined}
    </div>
  );
};

export default MobileContainer;
