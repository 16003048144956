/* React */
import React from "react";

/* Swiper-API */
import { SwiperClass } from "swiper/react";

/* Stylesheet */
import styles from "./Bullet.module.scss";

/* Interface */
interface BulletProps {
  index: number;
  active: boolean;
  smaller: boolean;
  swiperInstance: SwiperClass | null;
}

const Bullet: React.FC<BulletProps> = ({
  index,
  active,
  smaller,
  swiperInstance,
}) => {
  return (
    <div
      className={`${styles.bullet} ${active ? styles.active : ""} ${
        smaller ? styles.smaller : ""
      }`}
      onClick={() => swiperInstance?.slideTo(index)}
      style={{
        width: smaller ? "8px" : "10px",
        height: smaller ? "8px" : "10px",
        cursor: "pointer",
      }}
    />
  );
};

export default Bullet;
