/* React */
import React from "react";

/* Ionic */
import { IonContent, IonMenu } from "@ionic/react";

/* Hook */
import useKeyToggle from "src/utils/hooks/useKeyToggle";

/* Component */
import NavContainer from "src/components/menus/main-menu/nav-container/NavContainer";

/* Logo */
import Logo from "src/components/shared/logo/Logo";

/* Stylesheet */
import styles from "./MainMenu.module.scss";

const MainMenu: React.FC = () => {
  useKeyToggle({ key: "Escape", menuId: "main-menu", isDisabled: false });

  return (
    <IonMenu
      data-testid="main-menu"
      className={styles.main_menu}
      menuId="main-menu"
      contentId="main-content"
      type="push"
    >
      <IonContent className={styles.content_container}>
        <Logo />
        <NavContainer />
      </IonContent>
    </IonMenu>
  );
};

export default MainMenu;
