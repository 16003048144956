/* React */
import React, { useRef } from "react";

/* Ionic */
import { IonMenu, IonContent } from "@ionic/react";

/* React-Redux */
import { useDispatch } from "react-redux";
import { setIsOpen } from "src/redux/features/insights/insights-filter/insightsFilterMenuSlice";

/* Hook */
import useKeyToggle from "src/utils/hooks/useKeyToggle";

/* Components */
import InsightsFilter from "src/components/insights/insights-filter/InsightsFilter";
import InsightsOptions from "src/components/insights/insights-options/InsightsOptions";

/* Stylesheet */
import styles from "./InsightsFilterMenu.module.scss";

const InsightsFilterMenu: React.FC = () => {
  useKeyToggle({ key: " ", menuId: "filter-menu", isDisabled: false });
  const dispatch = useDispatch();

  return (
    <IonMenu
      className={styles.insights_filter_menu}
      menuId="filter-menu"
      contentId="filter-content"
      side="end"
      type="push"
      onIonWillClose={() => dispatch(setIsOpen(false))}
    >
      <IonContent className={styles.content_container}>
        <div className={styles.wrapper_container}>
          <InsightsFilter />
          <InsightsOptions />
        </div>
      </IonContent>
    </IonMenu>
  );
};

export default InsightsFilterMenu;
