/* React */
import React, { useEffect, useRef } from "react";

/* Ionic */
import { person } from "ionicons/icons";

/* React-Redux */
import { useDispatch, useSelector } from "react-redux";

import {
  setOptions,
  clearOptions,
} from "src/redux/features/insights/insights-filter/insightsFilterOptionsSlice";

import { RootState } from "src/redux/store";

/* Plotly */
import Plot from "react-plotly.js";

/* Utils */
import ChartWrapper from "src/utils/wrapper/chart-wrapper/ChartWrapper";
import { config, scatterPlotLayout } from "src/utils/config/chart-config";
import { createSurvivalPlotLayout } from "src/utils/helper/chart-helper";

/* Stylesheet */
import styles from "./SurvivalPlot.module.scss";

/* Interfaces */
import {
  ChartType,
  BaseChartProps,
} from "src/shared/interface/charts/BaseChart";

import {
  SurvivalPlotProps,
  TraceProps,
} from "src/shared/interface/charts/SurvivalPlot";
import { ChartLabelProps } from "src/shared/interface/common/ChartLabel";
import { createLegendInformation } from "src/utils/helper/legend";

const SurvivalPlot: React.FC<{
  index: number;
  stacked: boolean;
  data: BaseChartProps<SurvivalPlotProps[]>;
  showLabel?: boolean;
}> = ({ index, stacked, data }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const dispatch = useDispatch();

  // survivalPlotOptionsState
  const survivalPlotOptionsState = useSelector(
    (state: RootState) => state.survivalAnalysisOptions
  );

  const { showConfidenceIntervals } = survivalPlotOptionsState;

  useEffect(() => {
    dispatch(setOptions(ChartType.SCATTER));
    return () => {
      dispatch(clearOptions());
    };
  }, [dispatch]);

  // insightsFilterState
  const insightsFilterState = useSelector(
    (state: RootState) => state.insightsFilter
  );

  const { cohorts } = insightsFilterState;

  // survivalPlotData
  const survivalPlotData = data.data
    .filter((data: SurvivalPlotProps) => data.trace)
    .map((data: SurvivalPlotProps, index: number) => {
      return {
        ...createSurvivalPlotLayout(index, data, showConfidenceIntervals),
      };
    });

  const survivalPlot = (
    <div ref={ref}>
      <Plot
        className={styles.survival_plot}
        data={
          stacked
            ? survivalPlotData?.flatMap((data: SurvivalPlotProps) => [
                data.trace,
                data.censored,
                data.ciUpper,
                data.ciLower,
              ])
            : [survivalPlotData[index].trace]
        }
        config={config}
        layout={scatterPlotLayout}
        useResizeHandler={true}
      />
    </div>
  );

  const traces: TraceProps[] = survivalPlotData.map(
    (data: SurvivalPlotProps) => data.trace
  );

  const chartLabel: ChartLabelProps = {
    label: { icon: person, total: data.total!, name: "Patients" },
    featureOrCohort: { name: cohorts.value! },
    chart: survivalPlot,
    legend: createLegendInformation(traces),
  };

  return <ChartWrapper label={chartLabel}>{survivalPlot}</ChartWrapper>;
};

export default SurvivalPlot;
