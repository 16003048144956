/* React */
import React, { useRef } from "react";

/* Component(s) */
import SelectItem from "src/components/shared/select-container/select-item/SelectItem";

/* Stylesheet */
import styles from "./SelectItemContainer.module.scss";

const SelectItemContainer: React.FC<{
  itemKey: string;
  items: string[];
  handleShowValues: (value: boolean) => void;
  handleValue: (key: string, value: string) => void;
}> = ({ itemKey, items, handleShowValues, handleValue }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  let isMouseDown = false;
  let isTouching = false;
  let startY = 0;
  let scrollTop = 0;

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    isMouseDown = true;
    startY = e.pageY - containerRef.current!.offsetTop;
    scrollTop = containerRef.current!.scrollTop;
  };

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    isTouching = true;
    startY =
      e.touches[0].clientY - containerRef.current!.getBoundingClientRect().top;
    scrollTop = containerRef.current!.scrollTop;
  };

  const handleMouseLeave = () => {
    isMouseDown = false;
  };

  const handleMouseUp = () => {
    isMouseDown = false;
  };

  const handleTouchEnd = () => {
    isTouching = false;
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (isMouseDown) {
      const y = e.pageY - containerRef.current!.offsetTop;
      const walk = (y - startY) * 2;
      containerRef.current!.scrollTop = scrollTop - walk;
    }
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    if (isTouching) {
      const y =
        e.touches[0].clientY -
        containerRef.current!.getBoundingClientRect().top;
      const walk = (y - startY) * 2;
      containerRef.current!.scrollTop = scrollTop - walk;
    }
  };

  return (
    <div
      ref={containerRef}
      className={styles.select_item_container}
      onMouseDown={handleMouseDown}
      onMouseLeave={handleMouseLeave}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onTouchMove={handleTouchMove}
    >
      {items?.map((item, index) => {
        return (
          <SelectItem
            key={index}
            itemKey={itemKey}
            item={item}
            handleShowValues={handleShowValues}
            handleValue={handleValue}
          />
        );
      })}
    </div>
  );
};

export default SelectItemContainer;
