/* React */
import React from "react";

/* Swiper-API */
import { SwiperClass } from "swiper/react";
import { useSwiper } from "swiper/react";

/* Component */
import Bullet from "src/components/shared/swiper/swiper-controls/bullet-container/bullet/Bullet";

/* Stylesheet */
import styles from "./BulletContainer.module.scss";

/* Interface */
interface BulletContainerProps {
  scssProps?: ScssProps;
  swiperInstance?: SwiperClass | null;
  stacked?: boolean;
  length: number;
  activeIndex: number;
}

interface ScssProps {
  height?: string;
}

const MAX_VISIBLE: number = 5;

const BulletContainer: React.FC<BulletContainerProps> = ({
  scssProps,
  swiperInstance,
  length,
  activeIndex,
}) => {
  const swiper: SwiperClass = useSwiper();
  const dots: number[] = [];
  const halfVisible: number = Math.floor(MAX_VISIBLE / 2);
  let start: number = Math.max(0, activeIndex - halfVisible);
  const end: number = Math.min(length, start + MAX_VISIBLE);

  if (end - start < MAX_VISIBLE) {
    start = Math.max(0, end - MAX_VISIBLE);
  }

  for (let i = start; i < end; i++) {
    dots.push(i);
  }

  return (
    <div
      style={{ height: scssProps?.height }}
      className={styles.bullet_container}
    >
      <div className={styles.bullet_track}>
        {dots.map((dot: number, index: number) => {
          return (
            <Bullet
              key={index}
              index={index}
              active={dot === activeIndex}
              smaller={index === 0 || index === dots.length - 1}
              swiperInstance={swiperInstance ? swiperInstance : swiper}
            />
          );
        })}
      </div>
    </div>
  );
};

export default BulletContainer;
