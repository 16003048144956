/* React */
import React from "react";

/* Ionic */
import { IonFabButton, IonIcon } from "@ionic/react";
import { chevronBack, chevronForward } from "ionicons/icons";

/* Stylesheet */
import styles from "./Pagination.module.scss";

/* Interfaces */
interface PaginationProps {
  disabled: DisabledProps;
  currentPage: number;
  handlePrevPage?: () => void;
  handleNextPage?: () => void;
}

interface DisabledProps {
  prevDisabled: boolean;
  nextDisabled: boolean;
}

const Pagination: React.FC<PaginationProps> = ({
  disabled,
  currentPage,
  handlePrevPage,
  handleNextPage,
}) => {
  return (
    <div className={styles.pagination}>
      <IonFabButton
        data-testid="prev-fab-button"
        className={styles.fab_button}
        disabled={disabled?.prevDisabled}
        onClick={() => {
          if (!disabled.prevDisabled && handlePrevPage) {
            handlePrevPage();
          }
        }}
      >
        <IonIcon
          data-testid="prev-icon"
          style={{
            color: disabled?.prevDisabled
              ? "#d4d6dc"
              : "var(--ion-color-text-primary)",
          }}
          className={styles.icon}
          icon={chevronBack}
        />
      </IonFabButton>
      <div
        style={{
          color:
            disabled.prevDisabled && disabled.nextDisabled
              ? "#d4d6dc"
              : undefined,
        }}
        className={styles.current_page}
      >
        Page {currentPage}
      </div>
      <IonFabButton
        data-testid="next-fab-button"
        className={styles.fab_button}
        disabled={disabled?.nextDisabled}
        onClick={() => {
          if (!disabled.nextDisabled && handleNextPage) {
            handleNextPage();
          }
        }}
      >
        <IonIcon
          data-testid="next-icon"
          style={{
            color: disabled?.nextDisabled
              ? "#d4d6dc"
              : "var(--ion-color-text-primary)",
          }}
          className={styles.icon}
          icon={chevronForward}
        />
      </IonFabButton>
    </div>
  );
};

export default Pagination;
