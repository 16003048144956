/* pageTitleSlice.ts */

/* Redux-Toolkit */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InitialStateProps {
  title: string | undefined;
}

const initialState: InitialStateProps = {
  title: undefined,
};

export const pageTitleSlice = createSlice({
  name: "pageTitle",
  initialState,
  reducers: {
    setPageTitle: (state, action: PayloadAction<InitialStateProps>) => {
      state.title = action.payload.title;
    },
  },
});

/* Action */
export const { setPageTitle } = pageTitleSlice.actions;

/* Reducer */
export default pageTitleSlice.reducer;
