/* loginThunk.ts */

/* Redux-Toolkit */
import { createAsyncThunk } from "@reduxjs/toolkit";

/* Cookies */
import Cookies from "js-cookie";

/* Axios */
import axios from "axios";
import axiosInterceptor from "src/utils/helper/axios-interceptor";

/* HTTP Status Codes */
import { StatusCodes } from "http-status-codes";

/* Utils */
import { createCookies } from "src/utils/helper/cookies";

/* interface */
import { TokenType } from "src/shared/interface/api/Token";
import { BaseErrorProps } from "src/shared/interface/common/Error";
import { TwoFaResponse } from "src/shared/interface/api/TwoFa";
import { AuthProps } from "src/shared/interface/common/Auth";
import { OTPResponse } from "src/shared/interface/api/TwoFa";
import {
  BaseLoginRequest,
  TwoFaLoginRequest,
} from "src/shared/interface/api/Login";

export const loginWithCredentials = createAsyncThunk<
  AuthProps,
  BaseLoginRequest,
  { rejectValue: TwoFaResponse }
>(
  "auth/loginWithCredentials",
  async (credentials: BaseLoginRequest, { rejectWithValue }) => {
    try {
      const response = await axiosInterceptor.post("/login_with_credentials", {
        username_email: credentials.usernameOrEmail,
        password: credentials.password,
      });
      return createCookies(response);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const detail = error.response?.data.detail;

        if (error.response?.status === StatusCodes.BAD_REQUEST) {
          return rejectWithValue({
            showError: false,
            qrCode: detail.qrCode,
            qrCodeRequired: detail.qrCodeRequired,
            twoFaRequired: detail.twoFaRequired,
            credentials: credentials,
          });
        } else {
          return rejectWithValue({
            showError: true,
            message: detail,
          });
        }
      }
      return rejectWithValue({
        showError: true,
        message: "An unexpected error occurred",
        qrCodeRequired: false,
        twoFaRequired: false,
        credentials: {
          usernameOrEmail: "",
          password: "",
        },
      });
    }
  }
);

export const loginWithOTP = createAsyncThunk<
  AuthProps,
  TwoFaLoginRequest,
  { rejectValue: BaseErrorProps }
>(
  "auth/loginWithOTP",
  async (credentials: TwoFaLoginRequest, { rejectWithValue }) => {
    try {
      const response = await axiosInterceptor.post("/login_with_otp", {
        username_email: credentials.usernameOrEmail,
        password: credentials.password,
        code: credentials.code,
      });
      return createCookies(response);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const message = error.response?.data.detail;
        return rejectWithValue({ showError: true, message: message });
      }
      return rejectWithValue({
        showError: true,
        message: "An unexpected error occurred",
      });
    }
  }
);

export const qrCode = createAsyncThunk<
  OTPResponse,
  string,
  { rejectValue: BaseErrorProps }
>("auth/qrCode", async (usernameOrEmail, { rejectWithValue }) => {
  try {
    const response = await axiosInterceptor.get("/qr_code", {
      params: {
        username_email: usernameOrEmail,
      },
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const message = error.response?.data.detail;
      return rejectWithValue({ showError: true, message: message });
    }
    return { showError: true, message: "An unexpected error occurred" };
  }
});

/**
 * loginWithGoogle() is a process called 'token exchange'.
 * The client receives a token from Google and exchanges it with a token from Keycloak.
 * If user is not already registered, a new account will be created.
 * Therefore, Keycloak is used as the central authentication instance.
 **/
export const loginWithGoogle = createAsyncThunk<
  AuthProps,
  string,
  { rejectValue: BaseErrorProps }
>("auth/loginWithGoogle", async (accessToken: string, { rejectWithValue }) => {
  try {
    const response = await axiosInterceptor.post("/token_exchange", {
      access_token: accessToken,
    });
    return createCookies(response);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const message = error.response?.data.detail;
      return rejectWithValue({ showError: true, message: message });
    } else {
      throw new Error("Unexpected error");
    }
  }
});

export const refreshAccessToken = createAsyncThunk<
  AuthProps,
  void,
  { rejectValue: BaseErrorProps }
>("auth/refreshAccessToken", async (_, { rejectWithValue }) => {
  try {
    const response = await axiosInterceptor.get("/refresh_token");
    return createCookies(response);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const message = error.response?.data.message;
      return rejectWithValue({ showError: true, message: message });
    }
    return rejectWithValue({
      showError: true,
      message: "An unexpected error occurred",
    });
  }
});

export const logoutWithRefreshToken = createAsyncThunk<
  AuthProps,
  void,
  { rejectValue: BaseErrorProps }
>("auth/logoutWithRefreshToken", async (_, { rejectWithValue }) => {
  await axiosInterceptor.get(`/logout`);
  try {
    Cookies.remove(TokenType.ACCESS_TOKEN);
    Cookies.remove(TokenType.REFRESH_TOKEN);

    return {
      isAuthenticated: false,
      expirationTime: null,
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const message: string = error.response?.data.message;
      return rejectWithValue({ showError: true, message: message });
    }
    return rejectWithValue({
      showError: true,
      message: "An unexpected error occurred",
    });
  }
});
